const splitAddress = (address: string) => address.split(",")
const isThreeLineAddress = (address: string) => splitAddress(address).length === 4

export const getAddressLineOne = (address: string) => splitAddress(address)[0]
export const getAddressLineTwo = (address: string) => {
  const addressInfo = splitAddress(address)

  if (isThreeLineAddress(address)) {
    return addressInfo[1]
  }

  return `${addressInfo[1]}, ${addressInfo[2]}`
}
export const getAddressLineThree = (address: string) => {
  const addressInfo = splitAddress(address)

  if (isThreeLineAddress(address)) {
    return `${addressInfo[2]}, ${addressInfo[3]}`
  }

  return ""
}
export const getAddressLineOneAndTwo = (address: string) => {
  const addressInfo = splitAddress(address)

  return isThreeLineAddress(address) ? `${addressInfo[0]},${addressInfo[1]}` : addressInfo[0]
}

export const getCity = (address: string) => {
  const addressInfo = splitAddress(address)

  return isThreeLineAddress(address) ? addressInfo[2].trim() : addressInfo[1].trim()
}

export const getState = (address: string) => {
  const addressInfo = splitAddress(address)

  return isThreeLineAddress(address) ? addressInfo[3].trim().split(" ")[0] : addressInfo[2].trim().split(" ")[0]
}

export const getZip = (address: string) => {
  const addressInfo = splitAddress(address)

  return isThreeLineAddress(address) ? addressInfo[3].trim().split(" ")[1] : addressInfo[2].trim().split(" ")[1]
}
























import { getClientNameByClientKey } from "@/services/clientService"
import { getAllPoliciesByClientKey } from "@/services/policyService"
import { Policy } from "@/types/Policy"
import Vue from "vue"
import PolicyItem from "./PolicyItem.vue"

export default Vue.extend({
  components: { PolicyItem },
  props: {
    isUser: {
      type: Boolean,
      default: null,
    },
    isClientAdmin: {
      type: Boolean,
      default: null,
    },
    currentUser: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    error: false,
    isLoading: true,
    firmName: null,
    policies: null as null | Policy[],
    clientKey: null as null | string,
    showMessageSubmittedConfirmation: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.clientKey = this.getClientKey()
      await this.initialize()
    })
  },
  methods: {
    getClientKey() {
      const clientKeyInUrl = this.$route.params.clientKey

      if (clientKeyInUrl) {
        return clientKeyInUrl
      }

      return this.$props.currentUser.clientKey
    },
    async initialize() {
      this.firmName = await this.fetchClientInformation(this.clientKey as string)
      this.policies = await this.fetchClientPolicies()
      this.isLoading = false
    },

    async fetchClientInformation(clientKey: string) {
      const response = await getClientNameByClientKey(clientKey)
      return response
    },

    async fetchClientPolicies() {
      const response = await getAllPoliciesByClientKey(this.clientKey as string)
      return response
    },
  },
})








import { getClientNameByClientKey } from "@/services/clientService"
import Vue from "vue"

export default Vue.extend({
  data: () => ({
    firmName: "",
    firmNameLoaded: false,
  }),
  async mounted() {
    this.firmName = await getClientNameByClientKey(this.$route.params.clientKey)
  },
})

export const firmBannerStore = {
  state: {
    showBanner: false,
  },

  showBanner() {
    this.state.showBanner = true
  },

  hideBanner() {
    this.state.showBanner = false
  },
}

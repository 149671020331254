/**
 * This function will prompt the user to download the data supplied as a file with the given
 * type and file name.
 *
 * @param data The data to download as a file. This can be any valid file data.
 * @param fileName
 */
export const downloadFile = (data: any, fileName: string) => {
  const blob = new Blob([data])
  const fileURL = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = fileURL
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(link.href)
  link.remove()
}














































































































































































































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from "vue"
import { DOCUMENT_CATEGORY_KEY } from "@/constants/categoryKey"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { realEstateApplication } from "@/constants/policyApplicationForms"
import TextArea from "@/components/TextArea.vue"
import TextInput from "@/components/TextInput.vue"
import YesNoRadioGroup from "@/components/YesNoRadioGroup.vue"
import DocUploader from "@/components/Policies/Forms/DocUploader.vue"
import Form from "@/components/Policies/Forms/Form.vue"
import FormBody from "@/components/Policies/Forms/FormBody.vue"
import FormContent from "@/components/Policies/Forms/FormContent.vue"
import FormDatePicker from "@/components/Policies/Forms/FormDatePicker.vue"
import FormDetail from "@/components/Policies/Forms/FormDetail.vue"
import FormRow from "@/components/Policies/Forms/FormRow.vue"
import FormSection from "@/components/Policies/Forms/FormSection.vue"
import FormSectionContent from "@/components/Policies/Forms/FormSectionContent.vue"
import ListedQuestion from "@/components/Policies/Forms/ListedQuestion.vue"
import ApplicationLink from "@/components/Policies/PolicyApplications/ApplicationLink.vue"
import { PolicyFormDocuments } from "@/types/PolicyFormTypes"
import ExpansionContainer from "@/components/Policies/Forms/ExpansionContainer.vue"

type RealEstateDocuments = {
  [key: string]: string | PolicyFormDocuments[]
}

type expansionContainerReturnValue = {
  key: string
  type: string
  value: string | PolicyFormDocuments[]
}

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    ListedQuestion,
    TextInput,
    YesNoRadioGroup,
    TextArea,
    FormDatePicker,
    DocUploader,
    FormContent,
    FormBody,
    FormDetail,
    ApplicationLink,
    ExpansionContainer,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(true)],
  data: () => ({
    DOCUMENT_CATEGORY_KEY,
    formToSubmit: realEstateApplication,
    userSubmissionForm: {
      type: "realEstate",
      items: {
        firmname: null,
        policykey: null,
        attorneyNameOne: null,
        attorneyNameTwo: null,
        attorneyNameThree: null,
        attorneyNameFour: null,
        numberOfClosingsResidentialRecent: null,
        numberOfClosingsResidentialPrior: null,
        numberOfClosingsCommericalUnder1MRecent: null,
        numberOfClosingsCommericalUnder1MPrior: null,
        numberOfClosingsCommericalOver1MPrior: null,
        numberOfClosingsCommericalOver1MRecent: null,
        largestClosing: null,
        titleSearchesByAttorneysRecent: null,
        titleSearchesByAttorneysPrior: null,
        titleSearchesByNonAttorneysRecent: null,
        titleSearchesByNonAttorneysPrior: null,
        titleSearchesByThirdPartiesRecent: null,
        titleSearchesByThirdPartiesPrior: null,
        titleSearchesByThirdPartiesOmissionsCoverage: null,
        attorneyRemovedAsApproved: null,
        attorneyRemovedAsApprovedExplanation: null as string | null,
        certifyClosingNotSettlementAgent: null,
        certifyClosingNotSettlementAgentExplanation: null,
        interestInTitleAgency: null,
        representedLandDevelopment: null,
        representedCondominiums: null,
        closingWithoutAttorney: null,
        engagementLettersBuyer: null,
        engagementLettersSeller: null,
        engagementLettersBorrower: null,
        outsideNorthCarolina: null,
        outsideNorthCarolinaExplanation: null,
        closeLettersEquityFutureDeeds: null,
        photoIdRequired: null,
        altaCompliance: null,
        altaComplianceExplanation: null,
        wireProcedure: null,
        wireProcedureExplanation: null,
        signature: {
          signature: null,
          signDate: null,
        },
      },
    },
    userSubmissionDocuments: {
      spouseoremployeeinterestintitleagencyDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      attorneynotapprovedDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      altacomplianceDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      attorneyDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
    } as unknown as RealEstateDocuments,
  }),
  methods: {
    handleAttorneyRemovedAsApprovedChanged(value: expansionContainerReturnValue) {
      this.userSubmissionForm.items.attorneyRemovedAsApprovedExplanation = value.value as string
    },
  },
})

/**
 * Phone numbers in our backend are stored in the international format, but this app is not internationalized.
 * This function simply appends the +1 to phone numbers so that they follow the international format.
 */
export const normalizePhoneNumber = (phoneNumber: string) => `+1${phoneNumber}`

/**
 * This function removes the "+1" international prefix from normalized phone numbers
 */
export const denormalizePhoneNumber = (normalizedPhoneNumber?: string) =>
  typeof normalizedPhoneNumber === "undefined" ? "" : normalizedPhoneNumber.slice(2)

<template>
  <div id="formState" class="form-state-select">
    <Select
      :value="value"
      :label="label"
      full-width
      attach
      :items="statesListToRender"
      :required="required"
      @input="handleInput($event)"
    />
  </div>
</template>

<script>
import Select from "@/components/Select.vue"
import ABBREVIATED_STATES_LIST, { DEFAULT_ABBREVIATED_STATE_VALUE } from "@/constants/abbreviatedStatesList"
import FULL_STATES_LIST, { DEFAULT_FULL_STATE_VALUE } from "@/constants/fullStatesList"

export default {
  components: { Select },
  props: {
    abbreviated: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "State",
    },
    autopopulate: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    selectedState: null,
  }),
  computed: {
    statesListToRender() {
      if (this.abbreviated) {
        return ABBREVIATED_STATES_LIST
      }
      return FULL_STATES_LIST
    },
  },
  mounted() {
    if (this.autopopulate) {
      const defaultState = this.abbreviated ? DEFAULT_ABBREVIATED_STATE_VALUE : DEFAULT_FULL_STATE_VALUE
      this.$emit("input", defaultState)
    }
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value)
    },
  },
}
</script>

<style lang="scss" scoped>
.form-state-select {
  border-radius: 0 !important;
  width: 100%;
}

::v-deep {
  .v-select__selection--comma {
    color: var(--dark-grey) !important;
  }
  .v-list-item {
    min-height: 3.6rem;
  }
  .select-item {
    font-size: 1.2rem !important;
  }
}
</style>

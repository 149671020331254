







import { UserRole } from "@/constants/userRoles"
import { getBillingIFrame, getBillingIFrameForClient } from "@/services/billingService"
import { BillingIFrameResponse } from "@/types/BillingIframeResponse"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import { currentUserStateStore } from "@/stores/currentUserStore"
import Vue from "vue"

export default Vue.extend({
  props: {
    interfaceName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      iframeSrc: null as null | string,
      loadingLink: true,
      userStore: currentUserStateStore.state,
    }
  },
  mounted() {
    this.fetchLink()
  },
  methods: {
    getIframeForAdminOrClientUser(): Promise<BillingIFrameResponse> {
      if (this.userStore.user?.role === UserRole.LMNC_ADMIN) {
        return getBillingIFrameForClient(this.interfaceName, getClientKeyFromUserOrUrl(this, this.userStore.user))
      }

      return getBillingIFrame(this.interfaceName)
    },
    async fetchLink() {
      const iframeResponse = await this.getIframeForAdminOrClientUser()

      this.iframeSrc = iframeResponse.url

      this.loadingLink = false
    },
  },
})

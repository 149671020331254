



















import Vue from "vue"
import LmncSnackbar from "@/components/LmncSnackbar.vue"
import { scrollToElement } from "@/util/scrollUtils"
import { getErrorTextField } from "@/util/getErrorTextField"
import SubmitButton from "../../SubmitButton.vue"

const DEFAULT = ""
const BUTTON_CENTER = "button-centered"
const BUTTON_START = "button-start"
const SELECT_CLASS = "v-select"

export default Vue.extend({
  components: {
    SubmitButton,
    LmncSnackbar,
  },
  props: {
    buttonLabel: {
      type: String,
      default: "Submit",
    },
    minWidth: {
      type: String,
      default: "95rem",
    },
    buttonVariety: {
      type: String,
      default: DEFAULT,
    },
    formMargin: {
      type: String,
      default: "0 auto",
    },
    disableSubmission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showRequiredFieldError: false,
    }
  },
  computed: {
    buttonPosition() {
      if (this.buttonVariety === BUTTON_CENTER) {
        return "submit-btn-container justify-center align-center"
      }
      if (this.buttonVariety === BUTTON_START) {
        return "submit-btn-container justify-start align-center"
      }
      return "submit-btn-container"
    },
  },
  methods: {
    handleClick($event: Event) {
      const formRef = this.$refs.form as Vue & { validate: () => boolean }

      const valid = formRef.validate()

      if (!valid) {
        this.showRequiredFieldError = true

        this.$nextTick(() => {
          const errorField = getErrorTextField()

          // Selects cannot be scrolled to as their offsetTop is invalid
          if (!errorField.classList.contains(SELECT_CLASS)) {
            scrollToElement(errorField)
          }
        })
      } else {
        this.$emit("submit", $event)
      }
    },
    handleRequiredFormErrorClose() {
      this.showRequiredFieldError = false
    },
  },
})

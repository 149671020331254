








import Vue from "vue"

export default Vue.extend({
  props: {
    outsideSection: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    numberOfRows: null as null | number,
  }),
  computed: {
    contentOutsideSection(): string {
      return this.outsideSection ? "padding: 2.5rem 4rem" : ""
    },
  },
  mounted() {
    this.numberOfRows = this.getNumberOfRows()
  },
  methods: {
    getNumberOfRows(): number {
      if (this.$slots) {
        return Object.keys(this.$slots).length
      }

      return 0
    },
  },
})

import { clientStore } from "@/stores/clientStore"
import { ContactInfoResponse } from "@/types/ContactInfo"
import { UserRecord } from "@/types/UserRecord"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getClientsUrl, getUserUrl } from "@/util/urls"

export const addPaginationQuery = (paginated?: boolean, pageSize?: number, pageNumber?: number) =>
  paginated && pageSize && pageNumber ? `?paginated=true&pageSize=${pageSize}&pageNumber=${pageNumber}` : ``

export const addSortBySortDirectionToQuery = (sortBy?: string, sortDirection?: string) =>
  sortBy && sortDirection ? `sortBy=${sortBy}&sortDirection=${sortDirection}` : ``

const addSearchQuery = (search: string) => (search?.trim().length > 0 ? `&search=${search}` : "")

export const getAllClients = async (
  pageSize: number,
  pageNumber: number,
  paginated: boolean,
  search: string
): Promise<any> => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getClientsUrl()}${addPaginationQuery(paginated, pageSize, pageNumber)}${addSearchQuery(search)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const getClientNameByClientKey = async (clientKey: string) => {
  if (clientStore.state.clientName !== null && clientStore.state.clientKey === clientKey) {
    return clientStore.state.clientName
  }

  try {
    const response: any = await makeAuthenticatedRequest(`${getClientsUrl()}/${clientKey}`, "GET")

    clientStore.setClientInfo(response.name, clientKey, response.status)

    return response.name
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const getClientByClientKey = async (clientKey: string) =>
  makeAuthenticatedRequest(`${getClientsUrl()}/${clientKey}`, "GET")

export const getClientContactInfo = async (clientKey: string) =>
  makeAuthenticatedRequest<ContactInfoResponse>(`${getClientsUrl()}/${clientKey}/contact-info`, "GET")

export const getUsersByClientKey = async (
  clientKey: string,
  pageSize: number,
  pageNumber: number,
  paginated: boolean
) => {
  try {
    const response: any = await makeAuthenticatedRequest(
      `${getClientsUrl()}/${clientKey}/users${addPaginationQuery(paginated, pageSize, pageNumber)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const getUserByUserId = (userId: string) => makeAuthenticatedRequest(`${getUserUrl()}/${userId}`, "GET")

export const registerUser = async (userRecord: UserRecord) => {
  try {
    const response: any = await makeAuthenticatedRequest(`${getUserUrl()}`, "POST", userRecord)
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const updateClientUserById = async (clientUserId: string, clientUserRecord: UserRecord) => {
  try {
    const response: any = await makeAuthenticatedRequest(`${getUserUrl()}/${clientUserId}`, "PATCH", clientUserRecord)
    return response.data
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const removeClientUser = async (clientUserId: string) => {
  try {
    const response = await makeAuthenticatedRequest(`${getUserUrl()}/${clientUserId}`, "DELETE")
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const sendUpdateContactEmail = (
  clientKey: string,
  mailingAddress: string,
  phone: string,
  email: string,
  clientContactPerson: string,
  signature: string,
  date: string
) =>
  makeAuthenticatedRequest(`${getClientsUrl()}/${clientKey}/contact-info-update-email`, "POST", {
    mailingAddress,
    phone,
    email,
    clientContactPerson,
    signature,
    date,
  })

export const sendConfirmationOfInsuranceEmail = (
  clientKey: string,
  certificateHolderName: string,
  certificateHolderStreetAddress: string,
  certificateHolderCity: string,
  certificateHolderState: string,
  certificateHolderZip: string,
  signature: string,
  signatureDate: string
) =>
  makeAuthenticatedRequest(`${getClientsUrl()}/${clientKey}/confirmation-of-insurance-email`, "POST", {
    certificateHolderName,
    certificateHolderStreetAddress,
    certificateHolderCity,
    certificateHolderState,
    certificateHolderZip,
    signature,
    signatureDate,
  })

import Vue from "vue"
import { getDocumentContent } from "@/services/policyService"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { downloadFile } from "@/util/downloadFile"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"

export const DocumentDownloadMixin = Vue.extend({
  data() {
    return {
      downloadingDocumentKeys: [] as string[],
    }
  },
  methods: {
    async handleDownload(documentKey: string, fileName: string, fileExtension: string) {
      this.downloadingDocumentKeys = [...this.downloadingDocumentKeys, documentKey]

      await safelyDoWithUserInfo(async user => {
        const fileData = await getDocumentContent(getClientKeyFromUserOrUrl(this, user), documentKey)
        downloadFile(fileData, `${fileName}.${fileExtension}`)
        this.downloadingDocumentKeys = this.downloadingDocumentKeys.filter(key => key !== documentKey)
      })
    },
  },
})

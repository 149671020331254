export class InvalidCredentialsError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, InvalidCredentialsError.prototype)
  }
}

export class MissingFieldsError extends Error {
  constructor() {
    super("Missing required fields")
    Object.setPrototypeOf(this, MissingFieldsError.prototype)
  }
}























import Vue from "vue"
import { FormGroupItem } from "../types/FormGroupItem"
import { getLetteredLabel } from "../util/getLetteredLabel"
import { validateRequired } from "../validators/validateRequired"

export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: true,
    },
    column: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: true,
    },
    lettered: {
      type: Boolean,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    asterisk: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      validateRequired,
    }
  },
  computed: {
    radioClass(): string {
      return this.row ? "row" : "column"
    },
    itemsWithLabel(): FormGroupItem[] {
      const radioGroupItems = this.items as FormGroupItem[]

      if (this.lettered) {
        return getLetteredLabel(radioGroupItems)
      }

      return radioGroupItems
    },
    asteriskText() {
      return this.asterisk ? "*" : ""
    },
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
  },
})













































import Vue from "vue"
import CloseIcon from "@/components/common_icons/CloseIcon.vue"

export default Vue.extend({
  components: {
    CloseIcon,
  },
  props: {
    dialogTitle: {
      type: String,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    alignActionButtonsRight: {
      type: Boolean,
      default: null,
    },
    outlinedActionsSection: {
      type: Boolean,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default: null,
    },
    dialogMaxWidth: {
      type: String,
      default: "50rem",
    },
    cardHeight: {
      type: String,
      default: "100%",
    },
  },

  computed: {
    actionButtonsContainerClass(): string {
      return this.alignActionButtonsRight ? "action-buttons-container align-right" : "action-buttons-container d-flex"
    },
  },
  methods: {
    closeModal() {
      this.$emit("dialog-closed")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
  },
})

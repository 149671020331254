import { UserRole } from "@/constants/userRoles"
// eslint-disable-next-line import/no-cycle
import { fetchUserInformation } from "@/services/authServices"
import getEnv from "@/util/env"

const serviceUrl = getEnv("VUE_APP_SERVICE_URL")

export const getUserUrl = () => `${serviceUrl}/users`

export const getClientsUrl = () => `${serviceUrl}/clients`

export const getDocumentsUrl = () => `${serviceUrl}/documents`

export const getMessagesUrl = () => `${serviceUrl}/messages`

export const getContactsUrl = () => `${serviceUrl}/contacts`

export const getQuotesUrl = () => `${serviceUrl}/quotes`

export const getBillingUrl = () => `${serviceUrl}/billing`

export const appendFirmsIfAdminViewingClient = async (url: string, clientKey: string) => {
  const currentUser = await fetchUserInformation()

  if (currentUser.role === UserRole.LMNC_ADMIN) {
    return `/firms/${clientKey}/dashboard${url}`
  }

  return url
}

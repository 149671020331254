

















import Vue from "vue"
import Loader from "./Loader.vue"

const ACTION_VARIETY = "action"
const SUBMIT_VARIETY = "submit"
const TEXT_VARIETY = "text"
const PRIMARY_TEXT_VARIETY = "primary-text"
const CANCEL_VARIETY = "cancel"

export default Vue.extend({
  components: { Loader },
  props: {
    /**
     * Available options: action, submit, text
     */
    variety: {
      type: String,
      default: ACTION_VARIETY,
    },
    uppercase: {
      type: Boolean,
      default: null,
    },
    minWidth: {
      type: String,
      default: "20rem",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isText(): boolean {
      return this.variety === TEXT_VARIETY || this.variety === PRIMARY_TEXT_VARIETY
    },
    color(): string {
      if (this.variety === ACTION_VARIETY) {
        return "secondary"
      }
      if (this.variety === SUBMIT_VARIETY || this.variety === TEXT_VARIETY) {
        return "white"
      }
      if (this.variety === PRIMARY_TEXT_VARIETY) {
        return "primary"
      }
      if (this.variety === CANCEL_VARIETY) {
        return "cancel"
      }
      return "secondary"
    },
    buttonClass(): string {
      return `lmnc-button ${this.variety}`
    },
  },
  methods: {
    handleClick($event: Event) {
      this.$emit("click", $event)
    },
  },
})

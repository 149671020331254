









































































import Vue from "vue"
import { getKeycloak } from "@/keycloak/keycloak"
import { CLIENT_STATUS } from "@/constants/clientStatus"
import { currentUserStateStore } from "@/stores/currentUserStore"
import { firmBannerStore } from "@/stores/firmBannerStore"
import { clientStore } from "@/stores/clientStore"
import { isAdminClientViewUrl } from "../../constants/adminDashboardPath"
import LawyersMutualLogo from "./LawyersMutualLogo.vue"
import Button from "../Button.vue"

export default Vue.extend({
  components: {
    LawyersMutualLogo,
    Button,
  },
  props: {
    isLmncAdmin: {
      type: Boolean,
      default: false,
    },
    isClientAdmin: {
      type: Boolean,
      default: false,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientStore: clientStore.state,
      currentUserState: currentUserStateStore.state,
      firmBannerStore: firmBannerStore.state,
    }
  },
  computed: {
    isAdminViewingClient() {
      return this.isLmncAdmin && isAdminClientViewUrl(this.$route.path)
    },
    clientKeyParam() {
      return this.$route.params.clientKey
    },
    logoButtonTo() {
      if (this.firmBannerStore.showBanner) {
        return `/firms/${this.$route.params.clientKey}/dashboard/policies`
      }

      return "/"
    },
    readableClientStatus(): string {
      if (this.clientStore.clientStatus === null || !CLIENT_STATUS.has(this.clientStore.clientStatus)) {
        return ""
      }

      return CLIENT_STATUS.get(this.clientStore.clientStatus)!
    },
  },
  methods: {
    handleProfileInfoRouteClick(value: string) {
      if (value === "user-management") {
        if (this.isLmncAdmin) {
          this.$router.push(`/firms/${this.$route.params.clientKey}/dashboard/users`)
        }
        if (this.isClientAdmin) {
          this.$router.push(`/firms/${this.currentUserState.user?.clientKey}`)
        }
      }
      if (value === "profile") {
        this.$router.push(`/profile`)
      }
      if (value === "logout") {
        this.handleLogout()
      }
    },

    async handleLogout() {
      try {
        await getKeycloak().logout()
      } catch (error) {
        this.$emit("error", { text: "Unable to logout", variety: "error" })
      }
    },
  },
})

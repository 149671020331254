











































































import Vue from "vue"
import { sendUpdateContactEmail } from "@/services/clientService"
import Form from "@/components/Policies/Forms/Form.vue"
import FormSection from "@/components/Policies/Forms/FormSection.vue"
import FormRow from "@/components/Policies/Forms/FormRow.vue"
import FormSectionContent from "@/components/Policies/Forms/FormSectionContent.vue"
import TextInput from "@/components/TextInput.vue"
import FormDatePicker from "@/components/Policies/Forms/FormDatePicker.vue"
import FormDetail from "@/components/Policies/Forms/FormDetail.vue"
import FormContent from "@/components/Policies/Forms/FormContent.vue"
import PolicySubmission from "@/components/Policies/PolicySubmission.vue"
import PolicyChangeConfirmationDialog from "@/components/Policies/PolicyChangeConfirmationDialog.vue"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"

const validatePhone = (value: string) => value.length === 14 || "Please enter a valid phone number"
const validateEmail = (value: string) => /.+@.+\..+/.test(value) || "Please enter a valid email"

export default Vue.extend({
  components: {
    Form,
    FormSection,
    FormRow,
    FormSectionContent,
    TextInput,
    FormDetail,
    FormContent,
    FormDatePicker,
    PolicySubmission,
    PolicyChangeConfirmationDialog,
  },
  data: () => ({
    mailingAddress: "",
    phone: "",
    email: "",
    clientContactPerson: "",
    signature: "",
    date: "",
    validatePhone,
    validateEmail,
    showSubmissionDialog: false,
    submittingChange: false,
    showConfirmationDialog: false,
    submissionErrorOccurred: false,
  }),
  methods: {
    handleSubmit() {
      this.showSubmissionDialog = true
    },
    handleSubmissionCancelled() {
      this.showSubmissionDialog = false
      this.submissionErrorOccurred = false
    },
    async updateContactInformation() {
      this.submittingChange = true

      safelyDoWithUserInfo(async user => {
        this.submissionErrorOccurred = false

        try {
          await sendUpdateContactEmail(
            user.clientKey,
            this.mailingAddress,
            this.phone,
            this.email,
            this.clientContactPerson,
            this.signature,
            this.date
          )

          this.showSubmissionDialog = false
          this.showConfirmationDialog = true
        } catch (error) {
          this.submissionErrorOccurred = true
        }

        this.submittingChange = false
      })
    },
    handleConfirmationDialogClosed() {
      this.showConfirmationDialog = false
      this.$router.back()
    },
  },
})

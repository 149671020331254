<template>
  <SlideUpToggleTransition>
    <div v-if="dirty">
      <div class="change-password-label">Password must:</div>
      <PasswordRequirement
        requirement-label="Be at least 12 characters long"
        :password-value="value"
        :dirty="dirty"
        rule-regex=".{12}"
      />
      <PasswordRequirement
        requirement-label="Contain an uppercase letter"
        :password-value="value"
        :dirty="dirty"
        rule-regex="[A-Z]+"
      />
      <PasswordRequirement
        requirement-label="Contain a lowercase letter"
        :password-value="value"
        :dirty="dirty"
        rule-regex="[a-z]+"
      />
      <PasswordRequirement
        requirement-label="Contain a number"
        :password-value="value"
        :dirty="dirty"
        rule-regex="[0-9]+"
      />
      <PasswordRequirement
        requirement-label="Contain a special character"
        :password-value="value"
        :dirty="dirty"
        rule-regex="[-+_!@#$%^&*.,?]+"
      />
    </div>
  </SlideUpToggleTransition>
</template>

<script>
import PasswordRequirement from "@/components/PasswordRequirement.vue"
import SlideUpToggleTransition from "@/components/SlideUpToggleTransition.vue"

export default {
  components: { PasswordRequirement, SlideUpToggleTransition },
  props: {
    value: {
      type: String,
      default: null,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.change-password-label {
  font-size: 1.2rem;
}
</style>

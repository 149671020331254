<template>
  <div>
    <PolicyCoverage :policy-key="policyKey" :client-key="clientKey" :pre-loaded-policy="policy" />
    <AttorneysInsured :policy-key="policyKey" />
    <ContactInformation :client-key="clientKey" />
  </div>
</template>

<script>
import { GetClientKey } from "../../mixins/getClientKey"
import AttorneysInsured from "./YourPolicyInformation/AttorneysInsured.vue"
import ContactInformation from "./YourPolicyInformation/ContactInformation.vue"
import PolicyCoverage from "./YourPolicyInformation/PolicyCoverage.vue"
import { getPolicyInfo } from "../../services/policyService"

export default {
  components: { PolicyCoverage, AttorneysInsured, ContactInformation },
  mixins: [GetClientKey],
  props: {
    currentUser: {
      type: Object,
      default: null,
    },
    policyKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      policy: null,
    }
  },
  methods: {
    async initializePolicy() {
      this.policy = await getPolicyInfo(this.clientKey, this.policyKey)
      this.isLoading = false
    },
  },
}
</script>

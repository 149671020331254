import { getKeycloak } from "@/keycloak/keycloak"
import axios, { AxiosRequestConfig, Method } from "axios"

/* eslint-disable default-param-last */
export const makeAuthenticatedRequest = async <ResponseType>(
  url: string,
  method: Method = "GET",
  body?: any,
  logError = true,
  responseType: AxiosRequestConfig["responseType"] = "json"
): Promise<ResponseType> => {
  try {
    const { token } = getKeycloak()

    const response = await axios({
      method,
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
      responseType,
    })

    return response.data
  } catch (error) {
    if (logError && error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(`An error occured while fetching data for url: '${url}', ${error.message}`)
    }

    return Promise.reject(error)
  }
}

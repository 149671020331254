

















import BillingLink from "@/components/Billing/BillingLink.vue"
import PolicyContent from "@/components/Policies/PolicyContent.vue"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import Vue from "vue"

type BillingTab = { label: string; to: string }

export default Vue.extend({
  components: { PolicyContent, BillingLink },
  data() {
    return {
      generatingLink: false,
      links: [] as BillingTab[],
    }
  },
  async mounted() {
    this.links = [
      {
        label: "Invoice and Payment History",
        to: await this.getAdminLinkIfOnAdminView("/billing"),
      },
      {
        label: "Payment Methods",
        to: await this.getAdminLinkIfOnAdminView("/billing/payment-methods"),
      },
      {
        label: "AutoPay",
        to: await this.getAdminLinkIfOnAdminView("/billing/auto-pay"),
      },
      {
        label: "Paperless",
        to: await this.getAdminLinkIfOnAdminView("/billing/paperless"),
      },
      {
        label: "Pay by Text",
        to: await this.getAdminLinkIfOnAdminView("/billing/pay-by-text"),
      },
    ]
  },
  methods: {
    getAdminLinkIfOnAdminView(url: string) {
      return appendFirmsIfAdminViewingClient(url, this.$route.params.clientKey)
    },
  },
})

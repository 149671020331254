




































































































































































































































































































































































































































































































































































































































import { DOCUMENT_CATEGORY_KEY } from "@/constants/categoryKey"
import Vue, { VueConstructor } from "vue"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { plaintiffPersonalInjuryApplication } from "@/constants/policyApplicationForms"
import TextArea from "@/components/TextArea.vue"
import FormDatePicker from "@/components/Policies/Forms/FormDatePicker.vue"
import DocUploader from "@/components/Policies/Forms/DocUploader.vue"
import Form from "@/components/Policies/Forms/Form.vue"
import FormContent from "@/components/Policies/Forms/FormContent.vue"
import FormDetail from "@/components/Policies/Forms/FormDetail.vue"
import FormRow from "@/components/Policies/Forms/FormRow.vue"
import FormSection from "@/components/Policies/Forms/FormSection.vue"
import FormSectionContent from "@/components/Policies/Forms/FormSectionContent.vue"
import FormStateSelect from "@/components/Policies/Forms/FormStateSelect.vue"
import ListedQuestion from "@/components/Policies/Forms/ListedQuestion.vue"
import TextInput from "@/components/TextInput.vue"
import ApplicationLink from "@/components/Policies/PolicyApplications/ApplicationLink.vue"
import YesNoRadioGroup from "@/components/YesNoRadioGroup.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    YesNoRadioGroup,
    TextArea,
    FormDatePicker,
    ListedQuestion,
    FormStateSelect,
    DocUploader,
    FormContent,
    FormDetail,
    ApplicationLink,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(true)],
  data: () => ({
    formToSubmit: plaintiffPersonalInjuryApplication,
    userSubmissionForm: {
      type: "plaintiffPersonalInjury",
      items: {
        firmname: null,
        policykey: null,
        plaintiffPersonalInjuryAttorneyOneName: null,
        plaintiffPersonalInjuryAttorneyTwoName: null,
        plaintiffPersonalInjuryAttorneyThreeName: null,
        plaintiffPersonalInjuryAttorneyFourName: null,
        plaintiffPersonalInjuryAttorneyOneYearsExperience: null,
        plaintiffPersonalInjuryAttorneyTwoYearsExperience: null,
        plaintiffPersonalInjuryAttorneyThreYearsExperience: null,
        plaintiffPersonalInjuryAttorneyFourYearsExperience: null,
        plaintiffPersonalInjuryAttorneyOneCaseload: null,
        plaintiffPersonalInjuryAttorneyTwoCaseload: null,
        plaintiffPersonalInjuryAttorneyThreeCaseload: null,
        plaintiffPersonalInjuryAttorneyFourCaseload: null,
        percentageOfTimeBodilyInjury: null,
        percentageOfTimeProductLiability: null,
        percentageOfTimeMedicalMalpractice: null,
        percentageOfTimeWorkersComp: null,
        dollarSizeBodilyInjury: null,
        dollarSizeProductLiability: null,
        dollarSizeMedicalMalpractice: null,
        dollarSizeWorkersComp: null,
        largestJudgment: null,
        classActionMassTort: null,
        classActionMassTortExplanation: null,
        outsideNorthCarolina: null,
        outsideNorthCarolinaStateOne: null,
        outsideNorthCarolinaStateOneNumberClients: null,
        outsideNorthCarolinaStateTwoNumberClients: null,
        outsideNorthCarolinaStateTwo: null,
        outsideNorthCarolinaExplanation: null,
        anticipateIncreaseOutOfState: null,
        statutesOfLimitationsProcedures: null,
        statutesOfLimitations90Days: null,
        signature: {
          signDate: null,
          signature: null,
        },
      },
    },
    userSubmissionDocuments: {
      attorneyDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
    },
  }),
})

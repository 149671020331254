










































































import Vue, { VueConstructor } from "vue"
import { sendConfirmationOfInsuranceEmail } from "@/services/clientService"
import Form from "@/components/Policies/Forms/Form.vue"
import FormSection from "@/components/Policies/Forms/FormSection.vue"
import FormRow from "@/components/Policies/Forms/FormRow.vue"
import FormSectionContent from "@/components/Policies/Forms/FormSectionContent.vue"
import FormStateSelect from "@/components/Policies/Forms/FormStateSelect.vue"
import TextInput from "@/components/TextInput.vue"
import FormDatePicker from "@/components/Policies/Forms/FormDatePicker.vue"
import FormDetail from "@/components/Policies/Forms/FormDetail.vue"
import FormContent from "@/components/Policies/Forms/FormContent.vue"
import PolicySubmission from "@/components/Policies/PolicySubmission.vue"
import PolicyChangeConfirmationDialog from "@/components/Policies/PolicyChangeConfirmationDialog.vue"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import { AutofillAddress } from "@/mixins/autoFillAddress"

export default (Vue as VueConstructor<Vue & ReturnType<typeof AutofillAddress>>).extend({
  components: {
    Form,
    FormSection,
    FormRow,
    FormSectionContent,
    TextInput,
    FormDetail,
    FormContent,
    FormDatePicker,
    PolicySubmission,
    PolicyChangeConfirmationDialog,
    FormStateSelect,
  },
  mixins: [AutofillAddress()],
  data: () => ({
    certificateHolderName: "",
    certificateHolderStreetAddress: "",
    certificateHolderState: "",
    certificateHolderZip: "",
    signature: "",
    signatureDate: "",
    showSubmissionDialog: false,
    submittingEmail: false,
    showConfirmationDialog: false,
    submissionErrorOccurred: false,
    city: "",
    state: "",
    zip: "",
  }),
  methods: {
    handleSubmit() {
      this.showSubmissionDialog = true
    },
    handleSubmissionCancelled() {
      this.showSubmissionDialog = false
      this.submissionErrorOccurred = false
    },
    async sendConfirmationOfInsuranceEmail() {
      this.submittingEmail = true

      safelyDoWithUserInfo(async user => {
        this.submissionErrorOccurred = false

        try {
          await sendConfirmationOfInsuranceEmail(
            getClientKeyFromUserOrUrl(this, user),
            this.certificateHolderName,
            this.certificateHolderStreetAddress,
            this.city,
            this.state,
            this.zip,
            this.signature,
            this.signatureDate
          )

          this.showSubmissionDialog = false
          this.showConfirmationDialog = true
        } catch (error) {
          this.submissionErrorOccurred = true
        }

        this.submittingEmail = false
      })
    },
    handleConfirmationDialogClosed() {
      this.showConfirmationDialog = false
      this.$router.back()
    },
  },
})

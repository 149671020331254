import Vue from "vue"
import VueMask from "v-mask"
import vueDebounce from "vue-debounce"
import Vuelidate from "vuelidate"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import { getKeycloak, configKeycloak } from "./keycloak/keycloak"

Vue.config.productionTip = false

Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(vueDebounce)

configKeycloak()

function setupTokenRefresh() {
  setInterval(() => {
    getKeycloak()
      .updateToken(70)
      .then()
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error("Failed to refresh token")
      })
  }, 240000)
}

getKeycloak()
  .init({ onLoad: "login-required", checkLoginIframe: false })
  .then(auth => {
    if (!auth) {
      window.location.reload()
    } else {
      new Vue({
        router,
        vuetify,
        render: h => h(App),
      }).$mount("#app")
    }
  })

setupTokenRefresh()
















import Vue from "vue"
import LmncDialog from "@/components/LmncDialog.vue"
import Button from "@/components/Button.vue"

export default Vue.extend({
  components: {
    LmncDialog,
    Button,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleDialogClosed() {
      this.$emit("closed")
    },
  },
})

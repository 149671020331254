import { Paginated } from "@/types/Paginated"
import { Quote } from "@/types/Quote"
import { QuoteDocument } from "@/types/QuoteDocument"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getQuotesUrl } from "@/util/urls"

export const getAllQuotesByClientKey = async (clientKey: string, pageNumber: number, pageSize: number) =>
  makeAuthenticatedRequest<Paginated<Quote>>(
    `${getQuotesUrl()}/${clientKey}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    "GET"
  )

export const getAllQuoteDocumentsByClientKey = async (clientKey: string, quoteKey: string, quoteVersion: number) =>
  makeAuthenticatedRequest<QuoteDocument[]>(
    `${getQuotesUrl()}/${clientKey}/${quoteKey}/${quoteVersion}/documents`,
    "GET"
  )













































































































































































import Vue, { VueConstructor } from "vue"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { midTermLimitChangeForm } from "@/constants/policyChangeForms"
import { AutofillAddress } from "@/mixins/autoFillAddress"
import Select from "@/components/Select.vue"
import Form from "../Forms/Form.vue"
import FormSection from "../Forms/FormSection.vue"
import FormStateSelect from "../Forms/FormStateSelect.vue"
import TextInput from "../../TextInput.vue"
import FormRow from "../Forms/FormRow.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import TextArea from "../../TextArea.vue"
import FormContent from "../Forms/FormContent.vue"
import FormBody from "../Forms/FormBody.vue"
import FormDetail from "../Forms/FormDetail.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormStateSelect,
    TextInput,
    FormRow,
    FormSectionContent,
    FormDatePicker,
    TextArea,
    FormContent,
    FormBody,
    FormDetail,
    Select,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(), AutofillAddress()],
  data: () => ({
    formToSubmit: midTermLimitChangeForm,
    userSubmissionForm: {
      type: "midTermLimitChange",
      description: "Request Mid-Term Limit Change",
      items: {
        firmname: null,
        policykey: null,
        officeaddress: null,
        city: null,
        state: null,
        zipcode: null,
        limitofliability: null,
        deductible: null,
        effectivedateofchange: null,
        changereason: null,
        signature: {
          authorizedrepsignature: null,
          authorizedrepsigndate: null,
        },
      },
    },
    limitOfLiabilityOptions: [
      { text: "$100,000/$300,000", value: "$100,000/$300,000" },
      { text: "$200,000/$600,000", value: "$200,000/$600,000" },
      { text: "$250,000/$750,000", value: "$250,000/$750,000" },
      { text: "$500,000/$500,000", value: "$500,000/$500,000" },
      { text: "$500,000/$1,000,000", value: "$500,000/$1,000,000" },
      { text: "$1,000,000/$1,000,000", value: "$1,000,000/$1,000,000" },
      { text: "$1,000,000/$2,000,000", value: "$1,000,000/$2,000,000" },
      { text: "$1,000,000/$3,000,000", value: "$1,000,000/$3,000,000" },
      { text: "$2,000,000/$2,000,000", value: "$2,000,000/$2,000,000" },
      { text: "$2,000,000/$4,000,000", value: "$2,000,000/$4,000,000" },
      { text: "$3,000,000/$3,000,000", value: "$3,000,000/$3,000,000" },
      { text: "$4,000,000/$4,000,000", value: "$4,000,000/$4,000,000" },
      { text: "$5,000,000/$5,000,000", value: "$5,000,000/$5,000,000" },
    ],
    deductibleOptions: [
      { text: "$1,000", value: "$1,000" },
      { text: "$2,000", value: "$2,000" },
      { text: "$2,500", value: "$2,500" },
      { text: "$3,000", value: "$3,000" },
      { text: "$4,000", value: "$4,000" },
      { text: "$5,000", value: "$5,000" },
      { text: "$7,500", value: "$7,500" },
      { text: "$10,000", value: "$10,000" },
      { text: "$15,000", value: "$15,000" },
      { text: "$20,000", value: "$20,000" },
      { text: "$25,000", value: "$25,000" },
    ],
  }),
})






















































import Vue from "vue"
import Subtitle from "@/components/Subtitle.vue"
import Address from "@/components/Address.vue"
import { getPolicyInfo } from "@/services/policyService"
import { currentUserStateStore } from "@/stores/currentUserStore"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { PolicyInfo } from "@/types/PolicyInfo"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import YourPolicyContent from "./YourPolicyContent.vue"
import YourPolicyBtn from "./YourPolicyBtn.vue"
import PolicyBalance from "../PolicyBalance.vue"
import PolicyItemText from "../PolicyDashboard/PolicyItemText.vue"

export default Vue.extend({
  components: { YourPolicyContent, YourPolicyBtn, PolicyBalance, Subtitle, PolicyItemText, Address },
  props: {
    clientKey: {
      type: String,
      default: null,
    },
    policyKey: {
      type: String,
      default: null,
    },
    preLoadedPolicy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentUserState: currentUserStateStore.state,
      policy: null as null | PolicyInfo,
    }
  },
  computed: {
    isLoading(): boolean {
      return this.policy === null
    },
    titleCasedName(): string {
      if (this.policy === null || typeof this.policy.namedInsured === "undefined") {
        return "None"
      }

      return this.policy.namedInsured
    },
  },
  mounted() {
    this.fetchPolicyInfo()
  },
  methods: {
    async fetchPolicyInfo() {
      if (this.preLoadedPolicy !== null) {
        this.policy = this.preLoadedPolicy
      } else {
        await safelyDoWithUserInfo(async user => {
          this.policy = await getPolicyInfo(getClientKeyFromUserOrUrl(this, user), this.policyKey)
        })
      }
    },
    async handleConfirmationOfInsuranceClicked() {
      await safelyDoWithUserInfo(async user => {
        this.$router.push(
          await appendFirmsIfAdminViewingClient(`/confirmation-of-insurance`, getClientKeyFromUserOrUrl(this, user))
        )
      })
    },
    convertDateTimeToString(value: string) {
      const date = new Date(value)
      const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
      const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
      return `${month}/${day}/${date.getFullYear()}`
    },
  },
})

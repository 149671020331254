




import Vue from "vue"
import BillingIframe from "./BillingIFrame.vue"

export default Vue.extend({
  components: { BillingIframe },
})

export const CLIENT_STATUS = new Map([
  ["A", "ActiveClient"],
  ["P", "ProspectiveClient"],
  ["R", "RetiredClientNotActive"],
  ["M", "MergedClient"],
  ["Q", "QuoteOnly"],
  ["W", "Warning"],
  ["X", "ReferToRemarks"],
  ["D", "DeletedClient"],
  ["F", "FormerClient"],
  ["O", "Other"],
])

<template>
  <div class="add-firm-user">
    <Loader :is-loading="firmName === null">
      <UserInformationTemplate
        :is-loading="isLoading"
        title="Add User"
        :return-btn="`Back to ${firmName}`"
        submit-label="Add"
        @back="handleBackButton"
        @submit="handleAddUser"
        @error="handleSubmissionError"
      ></UserInformationTemplate>
    </Loader>
  </div>
</template>

<script>
import { normalizePhoneNumber } from "@/util/normalizePhoneNumber"
import Loader from "@/components/Loader.vue"
import UserInformationTemplate from "@/components/LMNCAdmin/UserInformationTemplate.vue"
import { registerUser, getClientNameByClientKey } from "@/services/clientService"

export default {
  components: { UserInformationTemplate, Loader },
  data: () => ({
    isLoading: false,
    currentClientKey: null,
    firmName: null,
  }),
  mounted() {
    this.currentClientKey = this.$route.params.clientKey
    this.getFirmName()
  },
  methods: {
    handleBackButton() {
      this.$router.back()
    },
    async handleAddUser(user) {
      this.isLoading = true

      const userWithNormalizedPhone = { ...user, phone: normalizePhoneNumber(user.phone) }

      try {
        await registerUser(userWithNormalizedPhone)
        this.$emit("updated-firm-users", {
          text: `Added ${userWithNormalizedPhone.firstName} ${userWithNormalizedPhone.lastName}`,
          variety: "success",
        })
        this.isLoading = false
        this.handleBackButton()
      } catch (error) {
        this.$emit("updated-firm-users", { text: `Something went wrong`, variety: "error" })
        this.isLoading = false
      }
    },

    handleSubmissionError(value) {
      this.isLoading = true
      this.$emit("updated-firm-users", value)
      this.isLoading = false
    },
    async getFirmName() {
      this.firmName = await getClientNameByClientKey(this.currentClientKey)
    },
  },
}
</script>

<style lang="scss" scoped></style>



















import Vue from "vue"

export default Vue.extend({
  computed: {
    currentQuoteKey(): string {
      return this.$route.params.quoteKey
    },
    currentPolicyKey(): string {
      return this.$route.params.policyKey
    },
    quoteSelected(): boolean {
      return typeof this.$route.params.quoteKey !== "undefined"
    },
  },
})

import Vue from "vue"
import { getClientContactInfo } from "@/services/clientService"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { getAddressLineOneAndTwo, getCity, getState, getZip } from "@/util/addressUtils"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"

type AutoFillOptions = {
  addressPropertyName?: string
  cityPropertyName?: string
  statePropertyName?: string
  zipPropertyName?: string
}

export const AutofillAddress = (options: AutoFillOptions = {}) =>
  Vue.extend({
    data: () => ({
      city: "",
      state: "",
      zip: "",
      autofillingAddress: true,
      userSubmissionForm: {
        items: {} as { [key: string]: any },
      },
    }),
    mounted() {
      this.autoFillAddress()
    },
    methods: {
      autoFillAddress() {
        safelyDoWithUserInfo(async user => {
          const contactInfo = await getClientContactInfo(getClientKeyFromUserOrUrl(this, user))

          const addressLineOne = getAddressLineOneAndTwo(contactInfo.address)
          const city = getCity(contactInfo.address)
          const state = getState(contactInfo.address)
          const zip = getZip(contactInfo.address)

          const {
            addressPropertyName = "officeaddress",
            cityPropertyName = "city",
            statePropertyName = "state",
            zipPropertyName = "zipcode",
          } = options

          this.userSubmissionForm.items[addressPropertyName] = addressLineOne
          this.userSubmissionForm.items[cityPropertyName] = city
          this.userSubmissionForm.items[statePropertyName] = state
          this.userSubmissionForm.items[zipPropertyName] = zip

          this.city = city
          this.state = state
          this.zip = zip

          this.autofillingAddress = false
        })
      },
    },
  })

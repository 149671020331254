<template>
  <div class="p-left d-flex flex-column">
    <div class="d-flex align-center uploader-container">
      <FormBody class="uploader-label"><slot></slot></FormBody>
      <v-btn class="uploader-btn" plain @click="useFileInput">
        <v-icon class="uploader-icon">mdi-attachment</v-icon>
        <p class="icon-text" style="font-size: 1.4rem">Attach</p>
      </v-btn>
    </div>

    <div>
      <label>
        <input
          ref="documentUploader"
          type="file"
          accept=".pdf"
          class="file-input"
          name="file-upload"
          @change="uploadFile($event)"
        />
      </label>
      <div class="uploaded-docs-container p-left">
        <div v-for="(doc, index) in currentDocuments" :key="index" class="uploaded-doc d-flex align-center">
          <p class="document-name">{{ getNameWithoutUUID(doc.fileName) }}</p>
          <v-btn x-small plain class="remove-doc-btn" @click="removeFile(doc)">
            <v-icon class="remove-doc-icon">mdi-close</v-icon>
            <p class="icon-text">Remove</p>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormBody from "./FormBody.vue"

export default {
  components: { FormBody },
  props: {
    value: {
      type: Array,
      default: null,
    },
    categoryKey: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    currentDocuments: [],
  }),
  beforeUnmount() {
    this.currentDocuments.forEach(document => URL.revoke(document.data))
  },
  methods: {
    useFileInput() {
      const uploader = this.$refs.documentUploader
      uploader.click()
    },
    getNameWithoutUUID(fileName) {
      const lastIndexOfHyphen = fileName.lastIndexOf("-")
      const lastIndexOfDot = fileName.lastIndexOf(".")

      return `${fileName.slice(0, lastIndexOfHyphen)}${fileName.slice(lastIndexOfDot)}`
    },
    generateUniqueFileNumber() {
      return Math.random().toString().slice(2)
    },
    generateUniqueFileName(fileName) {
      const indexOfLastPeriod = fileName.lastIndexOf(".")

      return `${fileName.slice(0, indexOfLastPeriod)}-${this.generateUniqueFileNumber()}${fileName.slice(
        indexOfLastPeriod
      )}`
    },
    async uploadFile(event) {
      if (event.target.files.length !== 0) {
        const base64Promise = this.getBase64(event.target.files[0])
        const document = {
          base64Content: await base64Promise,
          fileName: this.generateUniqueFileName(event.target.files[0].name),
          categoryKey: this.categoryKey,
        }

        this.currentDocuments = [...this.currentDocuments, document]
        this.$emit("input", this.currentDocuments)
      }
    },
    getFileFormData(file) {
      const formData = new FormData()
      formData.append("document", file, file?.name)
      return formData
    },
    getBase64(file) {
      return new Promise(function returnResult(resolve, reject) {
        const reader = new FileReader()

        reader.onload = function readAsDataResult() {
          const base64FromDataUrl = reader.result.split("base64,")[1]
          resolve(base64FromDataUrl)
        }

        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },

    removeFile(document) {
      this.currentDocuments = this.currentDocuments.filter(currentDocument => currentDocument !== document)
      this.$emit("input", this.currentDocuments)
    },
  },
}
</script>

<style lang="scss" scoped>
.file-input {
  display: none;
}
.uploader-container {
  margin-bottom: 1rem;

  .uploader-label {
    padding-left: 0.5rem;
  }

  .uploader-btn {
    padding: 0.25rem 0.5rem;
    margin-left: 1rem;
    &:hover {
      color: var(--light-blue);
    }

    .uploader-icon {
      padding-top: 0.25rem;
      font-weight: 900;
      transform: rotate(-45deg);
    }
  }
}

.icon-text {
  text-transform: none;
  margin: auto;
  font-size: 1.2rem;
  padding-left: 0.25rem;
  font-weight: 400;
}

.uploaded-docs-container {
  margin-bottom: 1.5rem;
  .uploaded-doc {
    .document-name {
      font-size: 1.4rem;
      margin: auto 0;
    }

    .remove-doc-btn {
      margin: 0 0.5rem;
      padding: 0.25rem;
      text-decoration: none;
      &:hover {
        color: var(--red);
      }
    }
  }
}

.p-left {
  padding-left: 1.8rem;
}
</style>

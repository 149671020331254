








import { getAddressLineOne, getAddressLineThree, getAddressLineTwo } from "@/util/addressUtils"
import Vue from "vue"

export default Vue.extend({
  props: {
    address: {
      type: String,
      default: "",
    },
  },
  computed: {
    line1(): string {
      return getAddressLineOne(this.address)
    },
    line2(): string {
      return getAddressLineTwo(this.address)
    },
    line3(): string {
      return getAddressLineThree(this.address)
    },
  },
})









































































































































































































































































































import Vue, { VueConstructor } from "vue"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { extendedReportingForm } from "@/constants/policyChangeForms"
import Form from "@/components/Policies/Forms/Form.vue"
import FormSection from "../Forms/FormSection.vue"
import FormRow from "../Forms/FormRow.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import TextInput from "../../TextInput.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import YesNoRadioGroup from "../../YesNoRadioGroup.vue"
import FormStateSelect from "../Forms/FormStateSelect.vue"
import FormContent from "../Forms/FormContent.vue"
import FormBody from "../Forms/FormBody.vue"
import FormDetail from "../Forms/FormDetail.vue"
import AttorneyInsuredSelect from "../../AttorneyInsuredSelect.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormRow,
    FormSectionContent,
    TextInput,
    FormDatePicker,
    YesNoRadioGroup,
    FormStateSelect,
    FormBody,
    FormContent,
    FormDetail,
    AttorneyInsuredSelect,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(false, "departingfirmname")],
  data: () => ({
    formToSubmit: extendedReportingForm,
    userSubmissionForm: {
      type: "extendedReporting",
      description: "Request for ERE (Tail Coverage) Quote",
      items: {
        applicantname: null,
        applicantbarnumber: null as null | string,
        departingfirmname: null,
        policykey: null,
        departuredate: null,
        reasonretiring: null,
        reasonleavingpractice: null,
        additionalinformation: null,
        reasonmoveoutofnc: null,
        reasonjoinanotherfirm: null,
        newfirmname: null,
        reasondeceased: null,
        reasonother: null,
        applicantsuspended: null,
        forwardingname: null,
        forwardingstreetorpobox: null,
        forwardingcity: null,
        forwardingstate: null,
        forwardingzip: null,
        forwardingphonenumber: null,
        forwardingemail: null,
        signature: {
          estatesignature: null,
          estatesigndate: null,
        },
      },
    },
  }),
  methods: {
    handleBarNumberSelected(barNumber: string) {
      this.userSubmissionForm.items.applicantbarnumber = barNumber
    },
  },
})

import { isSafari } from "./isSafari"

const SUFFIX_REGEX = /^[VI]+$/
const IRREGULAR_NAME_PART_REGEX_PORTION = "^MC|^MAC|'"
const IRREGULAR_NAME_PART = new RegExp(`(${IRREGULAR_NAME_PART_REGEX_PORTION})`)

const abbreviateIfNeeded = (part: string) => (part.length < 3 && !IRREGULAR_NAME_PART.test(part) ? "." : "")

// Because safari will bail if it detects a lookbehind operator in a regex when it's constructed, we have to create the regex here inline
const capitalizeNameParts = (name: string) =>
  name
    .split(new RegExp(`(?<=(?:${IRREGULAR_NAME_PART_REGEX_PORTION}))`))
    .map(s => `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}${abbreviateIfNeeded(s)}`)
    .join("")

// Safari does not yet support lookbehind operators in regexes, so we don't format names when that browser is detected
export const toTitleCaseWithAbbreviations = (text: string) =>
  isSafari()
    ? text
    : text
        .split(" ")
        .map(part => {
          if (SUFFIX_REGEX.test(part)) {
            return part.toUpperCase()
          }

          return capitalizeNameParts(part)
        })
        .join(" ")











import Vue from "vue"
import Button from "@/components/Button.vue"

const iconMap = new Map([
  ["edit", "mdi-pencil-box-outline"],
  ["plus", "mdi-plus-circle-outline"],
  ["minus", "mdi-minus-circle-outline"],
])

export default Vue.extend({
  components: { Button },
  props: {
    icon: {
      type: String,
      default: "edit",
    },
    fontSize: {
      type: String,
      default: "1.6rem",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconType() {
      if (iconMap.has(this.icon)) {
        return iconMap.get(this.icon)
      }

      return null
    },
  },
  methods: {
    handleClick($event: Event) {
      this.$emit("click", $event)
    },
  },
})

import Vue from "vue"
import { PolicyForm, PolicyFormData, PolicyFormDocuments } from "@/types/PolicyFormTypes"

export const FormSubmissionMixin = Vue.extend({
  data() {
    return {
      showRequiredFieldError: false,
      formToSubmit: null as any,
      userSubmissionForm: null as any,
      userSubmissionDocuments: null as any,
    }
  },
  methods: {
    isSignatureValid(messageData: any) {
      const { signature } = messageData.items

      if (typeof signature === "undefined") {
        // eslint-disable-next-line no-console
        console.error(
          "A message form was submitted without a signature property -- please add one to this message payload"
        )
      }

      return (
        !Object.values(signature).includes(null) &&
        Object.values(signature).find(value => typeof value === "string" && value.trim().length === 0) === undefined
      )
    },
    flattenSignatureProperties(messageData: any) {
      return {
        ...messageData,
        items: {
          ...messageData.items,
          ...messageData.items.signature,
        },
      }
    },
    handleRequiredFormErrorClose() {
      this.showRequiredFieldError = false
    },
    handleSubmit() {
      if (this.formToSubmit === null) {
        // eslint-disable-next-line no-console
        console.error("Form submission mixin used without specifying the formToSubmit property")
        return
      }

      if (!this.isSignatureValid(this.userSubmissionForm)) {
        this.$emit("signature-required-error")
      } else {
        const messageDataWithSignatureFieldsFlattened = this.flattenSignatureProperties(this.userSubmissionForm)

        const formItems = this.setPolicyFormForSubmission(messageDataWithSignatureFieldsFlattened, this.formToSubmit)
        const documents = this.setDocumentsForSubmission(this.userSubmissionDocuments)

        const formReadyForSubmission = {
          type: this.userSubmissionForm.type,
          messageFields: formItems,
          documents,
        }

        this.$emit("submit", formReadyForSubmission)
      }
    },

    setPolicyFormForSubmission(userSubmittedForm: any, policyForm: PolicyForm): PolicyFormData[] {
      return policyForm.items.map(item => {
        const itemName = item.name
        const value = userSubmittedForm.items[itemName]

        let parsedValue = value

        if (Array.isArray(value)) {
          parsedValue = value.join(", ")
        }

        return { ...item, value: parsedValue }
      })
    },

    setDocumentsForSubmission(userSubmittedDocuments: {
      [index: string]: PolicyFormDocuments[]
    }): PolicyFormData[] | unknown {
      if (userSubmittedDocuments !== null) {
        return Object.values(userSubmittedDocuments)
          .flat()
          .filter(document => document.base64Content !== null)
      }

      return null
    },
  },
})

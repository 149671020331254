





























import { validateRequired } from "@/validators/validateRequired"
import Vue from "vue"

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 6,
    },
    rowHeight: {
      type: Number,
      default: 20,
    },
    autoGrow: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelWrap: {
      type: Boolean,
      default: null,
    },
    mediumLabel: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dirty: false,
      validateRequired,
    }
  },
  computed: {
    classList(): string {
      return `lmnc-textarea ${this.labelWrap ? "label-wrap" : ""}`
    },
    errorMessagesWithRequiredError(): string[] {
      if (this.error && this.required && this.value.trim().length === 0) {
        return ["This field is required"]
      }

      return this.errorMessages as string[]
    },
  },
  methods: {
    handleInput(value: string) {
      this.dirty = true
      this.$emit("input", value)
    },
    handleBlur(event: Event) {
      this.$emit("blur", event)
    },
  },
})



























































































































































































































































































































































import Vue, { VueConstructor } from "vue"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { attorneyRemovalForm } from "@/constants/policyChangeForms"
import { AutofillAddress } from "@/mixins/autoFillAddress"
import Form from "../Forms/Form.vue"
import FormSection from "../Forms/FormSection.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import FormRow from "../Forms/FormRow.vue"
import TextInput from "../../TextInput.vue"
import FormStateSelect from "../Forms/FormStateSelect.vue"
import YesNoRadioGroup from "../../YesNoRadioGroup.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import FormContent from "../Forms/FormContent.vue"
import FormBody from "../Forms/FormBody.vue"
import FormDetail from "../Forms/FormDetail.vue"
import AttorneyInsuredSelect from "../../AttorneyInsuredSelect.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    FormStateSelect,
    YesNoRadioGroup,
    FormDatePicker,
    FormContent,
    FormBody,
    FormDetail,
    AttorneyInsuredSelect,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(), AutofillAddress()],
  data: () => ({
    formToSubmit: attorneyRemovalForm,
    userSubmissionForm: {
      type: "attorneyRemoval",
      items: {
        firmname: null,
        policykey: null,
        officeaddress: null,
        city: null,
        state: null,
        zipcode: null,
        departingattorneyname: null,
        datetoberemoved: null,
        firmnotifieddepartingattorney: null,
        departingattorneyretiring: null,
        departingattorneypracticeonown: null,
        departingattorneymovingoutofnc: null,
        departingattorneyjoinanotherfirm: null,
        newfirmname: null,
        departingattorneydeceased: null,
        forwardingaddress: null,
        forwardingcity: null,
        forwardingstate: null,
        forwardingzip: null,
        forwardingphonenumber: null,
        forwardingemail: null,
        signature: {
          authorizedrepsignature: null,
          authorizedrepsigndate: null,
        },
      },
    },
  }),
})

import { UserRecordWithClient } from "@/types/UserRecord"
import Vue from "vue"

export const getClientKeyFromUserOrUrl = (component: Vue, user: UserRecordWithClient) => {
  if (user.clientKey) {
    return user.clientKey
  }

  return component.$route.params.clientKey
}

<template>
  <a class="application-link" target="_blank" :href="link">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.application-link {
  text-decoration: underline;
}
</style>

import { PolicyForm } from "@/types/PolicyFormTypes"

export const estatesTrustsPolicyApplication: PolicyForm = {
  type: "estatesTrusts",
  description: "Estates & Trusts",
  items: [
    { name: "firmname", value: null },
    { name: "policykey", value: null },
    { name: "attorneyNameOne", value: null },
    { name: "attorneyNameTwo", value: null },
    { name: "attorneyNameThree", value: null },
    { name: "attorneyNameFour", value: null },
    { name: "simpleWills", value: null },
    { name: "engagementLetterIndividualInEstateTaxPlanning", value: null },
    { name: "engagementLetterIndividualInEstateTaxPlanningExpln", value: null },
    { name: "engagementLetterPersonalRepresentatitveOrEstate", value: null },
    { name: "engagementLetterPersonalRepresentatitveOrEstateExp", value: null },
    { name: "disengagementLetters", value: null },
    { name: "estateTaxReturnYearNumberRange", value: null },
    { name: "estateTaxReturnThreeYearNumberRange", value: null },
    { name: "notifyNotResponsible", value: null },
    { name: "netWorthLessThan5MPercentage", value: null },
    { name: "netWorthBetween5MAnd10MPercentage", value: null },
    { name: "netWorthGreaterThan10MPercentage", value: null },
    { name: "administrationWorkLessThan5MPercentage", value: null },
    { name: "administrationWorkBetween5MAnd10MPercentage", value: null },
    { name: "administrationWorkGreaterThan10MPercentage", value: null },
    { name: "purchaseOrSaleOfSecurities", value: null },
    { name: "purchaseOrSaleOfRealEstate", value: null },
    { name: "purchaseOrSaleOfOtherInvestments", value: null },
    { name: "adviseToRetainQualifiedProfesional", value: null },
    { name: "adviseToRetainQualifiedProfesionalExplanation", value: null },
    { name: "authorizedrepsignaturedate", value: null },
    { name: "authorizedrepsignature", value: null },
  ],
}

export const intellectualPropertyApplication: PolicyForm = {
  type: "intellectualProperty",
  description: "Policy Application - Intellectual Property",
  items: [
    { name: "firmname", value: null },
    { name: "policykey", value: null },
    { name: "intellectualPropertyLawAttorneyNames", value: null },
    { name: "percentageDomesticPatent", value: null },
    { name: "percentageForeignPatentApplication", value: null },
    { name: "percentageDomesticPatentLitigation", value: null },
    { name: "percentageForeignPatentLitigation", value: null },
    { name: "percentageDomesticPatentSearches", value: null },
    { name: "perentageForeignPatentSearches", value: null },
    { name: "percentageTrademarkCopyrightRegistration", value: null },
    { name: "otherPracticeAreaName", value: null },
    { name: "otherPracticeAreaPercentage", value: null },
    { name: "percentageSpecializationChemical", value: null },
    { name: "percentageSpecializationComputers", value: null },
    { name: "percentageSpecializationElectronic", value: null },
    { name: "percentageSpecializationElectronic", value: null },
    { name: "percentageSpecializationMechanical", value: null },
    { name: "percentageSpecializationMedical", value: null },
    { name: "otherSpecializationName", value: null },
    { name: "otherSpecializationPercentage", value: null },
    { name: "clientOneName", value: null },
    { name: "clientOneTypeOfBusiness", value: null },
    { name: "clientOneLegalServicesProvided", value: null },
    { name: "clientTwoName", value: null },
    { name: "clientTwoTypeOfBusiness", value: null },
    { name: "clientTwoLegalServicesProvided", value: null },
    { name: "clientThreeName", value: null },
    { name: "clientThreeTypeOfBusiness", value: null },
    { name: "clientThreeLegalServicesProvided", value: null },
    { name: "percentageClientsFortune500", value: null },
    { name: "percentageClientsOtherPublicyTraded", value: null },
    { name: "percentageClientsPrivatelyHeld", value: null },
    { name: "percentageClientsIndividualInventors", value: null },
    { name: "otherClientPercentage", value: null },
    { name: "otherClientType", value: null },
    { name: "sendTerminationLetters", value: null },
    { name: "responsibilityForMaintenanceAnnuity", value: null },
    { name: "responsibilityForMaintenanceAnnuityExplanation", value: null },
    { name: "thirdParties", value: null },
    { name: "thirdPartiesExplanation", value: null },
    { name: "foreignFilingDeadlinesRequirements", value: null },
    { name: "engagementLetter", value: null },
    { name: "qualifyOpinion", value: null },
    { name: "litigatedForMattersAlsoHandling", value: null },
    { name: "acknowledgeTerritoriesAndCountries", value: null },
    { name: "acknowledgeTerritoriesAndCountriesExplanation", value: null },
    { name: "signDate", value: null },
    { name: "signature", value: null },
  ],
}

export const mergersAcquisitionsApplication: PolicyForm = {
  type: "mergersAcquisitionsApplication",
  description: "Policy Application - Mergers & Acquisitions",
  items: [
    { name: "firmname", value: null },
    { name: "policykey", value: null },
    { name: "mergersAcquisitionsAttorneyNames", value: null },
    { name: "renderedServicesToMergerOfPublicCompanies", value: null },
    { name: "renderedServicesToMergerOfPrivateCompanies", value: null },
    { name: "renderedServicesToAcquisitionOfPublicCompanies", value: null },
    { name: "renderedServicesToAcquisitionOfPrivateCompanies", value: null },
    { name: "largestMergerDollarAmountOne", value: null },
    { name: "largestMergerDollarAmountTwo", value: null },
    { name: "largestMergerDollarAmountThree", value: null },
    { name: "largestAcquisitionDollarAmountOne", value: null },
    { name: "largestAcquisitionDollarAmountTwo", value: null },
    { name: "largestAcquisitionDollarAmountThree", value: null },
    { name: "registrationOfSecurities", value: null },
    { name: "engagementLetter", value: null },
    { name: "equityInterest", value: null },
    { name: "representedAcquiringAndAcquired", value: null },
    { name: "representedAcquiringAndAcquiredDiscloseConflicts", value: null },
    { name: "representedBothPartiesMerger", value: null },
    { name: "representedBothPartiesMergerDisclosedConflicts", value: null },
    { name: "signDate", value: null },
    { name: "signature", value: null },
  ],
}

export const plaintiffPersonalInjuryApplication: PolicyForm = {
  type: "plaintiffPersonalInjury",
  description: "Policy Application - Plaintiff Personal Injury",
  items: [
    { name: "firmname", value: null },
    { name: "policykey", value: null },
    { name: "plaintiffPersonalInjuryAttorneyOneName", value: null },
    { name: "plaintiffPersonalInjuryAttorneyTwoName", value: null },
    { name: "plaintiffPersonalInjuryAttorneyThreeName", value: null },
    { name: "plaintiffPersonalInjuryAttorneyFourName", value: null },
    { name: "plaintiffPersonalInjuryAttorneyOneYearsExperience", value: null },
    { name: "plaintiffPersonalInjuryAttorneyTwoYearsExperience", value: null },
    { name: "plaintiffPersonalInjuryAttorneyThreYearsExperience", value: null },
    { name: "plaintiffPersonalInjuryAttorneyFourYearsExperience", value: null },
    { name: "plaintiffPersonalInjuryAttorneyOneCaseload", value: null },
    { name: "plaintiffPersonalInjuryAttorneyTwoCaseload", value: null },
    { name: "plaintiffPersonalInjuryAttorneyThreeCaseload", value: null },
    { name: "plaintiffPersonalInjuryAttorneyFourCaseload", value: null },
    { name: "percentageOfTimeBodilyInjury", value: null },
    { name: "percentageOfTimeProductLiability", value: null },
    { name: "percentageOfTimeMedicalMalpractice", value: null },
    { name: "percentageOfTimeWorkersComp", value: null },
    { name: "dollarSizeBodilyInjury", value: null },
    { name: "dollarSizeProductLiability", value: null },
    { name: "dollarSizeMedicalMalpractice", value: null },
    { name: "dollarSizeWorkersComp", value: null },
    { name: "largestJudgment", value: null },
    { name: "classActionMassTort", value: null },
    { name: "classActionMassTortExplanation", value: null },
    { name: "outsideNorthCarolina", value: null },
    { name: "outsideNorthCarolinaStateOne", value: null },
    { name: "outsideNorthCarolinaStateOneNumberClients", value: null },
    { name: "outsideNorthCarolinaStateTwoNumberClients", value: null },
    { name: "outsideNorthCarolinaStateTwo", value: null },
    { name: "outsideNorthCarolinaExplanation", value: null },
    { name: "anticipateIncreaseOutOfState", value: null },
    { name: "statutesOfLimitationsProcedures", value: null },
    { name: "statutesOfLimitations90Days", value: null },
    { name: "signDate", value: null },
    { name: "signature", value: null },
  ],
}

export const realEstateApplication: PolicyForm = {
  type: "realEstate",
  description: "Policy Application - Real Estate",
  items: [
    { name: "firmname", value: null },
    { name: "policykey", value: null },
    { name: "attorneyNameOne", value: null },
    { name: "attorneyNameTwo", value: null },
    { name: "attorneyNameThree", value: null },
    { name: "attorneyNameFour", value: null },
    { name: "numberOfClosingsResidentialRecent", value: null },
    { name: "numberOfClosingsResidentialPrior", value: null },
    { name: "numberOfClosingsCommericalUnder1MRecent", value: null },
    { name: "numberOfClosingsCommericalUnder1MPrior", value: null },
    { name: "numberOfClosingsCommericalOver1MPrior", value: null },
    { name: "numberOfClosingsCommericalOver1MRecent", value: null },
    { name: "largestClosing", value: null },
    { name: "titleSearchesByAttorneysRecent", value: null },
    { name: "titleSearchesByAttorneysPrior", value: null },
    { name: "titleSearchesByNonAttorneysRecent", value: null },
    { name: "titleSearchesByNonAttorneysPrior", value: null },
    { name: "titleSearchesByThirdPartiesRecent", value: null },
    { name: "titleSearchesByThirdPartiesPrior", value: null },
    { name: "titleSearchesByThirdPartiesOmissionsCoverage", value: null },
    { name: "attorneyRemovedAsApproved", value: null },
    { name: "attorneyRemovedAsApprovedExplanation", value: null },
    { name: "certifyClosingNotSettlementAgent", value: null },
    { name: "certifyClosingNotSettlementAgentExplanation", value: null },
    { name: "interestInTitleAgency", value: null },
    { name: "representedLandDevelopment", value: null },
    { name: "representedCondominiums", value: null },
    { name: "closingWithoutAttorney", value: null },
    { name: "outsideNorthCarolina", value: null },
    { name: "outsideNorthCarolinaExplanation", value: null },
    { name: "engagementLettersBuyer", value: null },
    { name: "engagementLettersSeller", value: null },
    { name: "engagementLettersBorrower", value: null },
    { name: "closeLettersEquityFutureDeeds", value: null },
    { name: "photoIdRequired", value: null },
    { name: "altaCompliance", value: null },
    { name: "altaComplianceExplanation", value: null },
    { name: "wireProcedure", value: null },
    { name: "wireProcedureExplanation", value: null },
    { name: "signDate", value: null },
    { name: "signature", value: null },
  ],
}

export const securitiesApplication: PolicyForm = {
  type: "securities",
  description: "Policy Application - Securities",
  items: [
    { name: "policykey", value: null },
    { name: "firmname", value: null },
    { name: "attorneyNameOne", value: null },
    { name: "attorneyNameTwo", value: null },
    { name: "attorneyNameThree", value: null },
    { name: "attorneyYearsExperienceOne", value: null },
    { name: "attorneyYearsExperienceTwo", value: null },
    { name: "attorneyYearsExperienceThree", value: null },
    { name: "attorneyAgencyOne", value: null },
    { name: "attorneyAgencyTwo", value: null },
    { name: "attorneyAgencyThree", value: null },
    { name: "attorneyYearsOfServiceOne", value: null },
    { name: "attorneyYearsOfServiceTwo", value: null },
    { name: "attorneyYearsOfServiceThree", value: null },
    { name: "dollarsDerived", value: null },
    { name: "percentageSecuritiesWork", value: null },
    { name: "lawyerReprimanded", value: null },
    { name: "representIssures", value: null },
    { name: "representUnderwriters", value: null },
    { name: "representBrokerDealers", value: null },
    { name: "representInvestmentAdvisers", value: null },
    { name: "representInvestors", value: null },
    { name: "largestClientOne", value: null },
    { name: "largestClientOneTypeOfBusiness", value: null },
    { name: "largestClientTwoTypeOfBusiness", value: null },
    { name: "largestClientThreeTypeOfBusiness", value: null },
    { name: "largestClientFourTypeOfBusiness", value: null },
    { name: "largestClientFour", value: null },
    { name: "largestClientThree", value: null },
    { name: "largestClientTwo", value: null },
    { name: "federalSecuritiesRegistrationDateOne", value: null },
    { name: "federalSecuritiesRegistrationDateTwo", value: null },
    { name: "federalSecuritiesRegistrationDateThree", value: null },
    { name: "federalSecuritiesClientNameOne", value: null },
    { name: "federalSecuritiesClientNameTwo", value: null },
    { name: "federalSecuritiesClientNameThree", value: null },
    { name: "federalSecuritiesSizeOfferingOne", value: null },
    { name: "federalSecuritiesSizeOfferingTwo", value: null },
    { name: "federalSecuritiesSizeOfferingThree", value: null },
    { name: "stopOrder", value: null },
    { name: "stopOrderExplanation", value: null },
    { name: "entityTypes", value: null },
    { name: "negativeAssurance", value: null },
    { name: "finraDateOne", value: null },
    { name: "finraDateTwo", value: null },
    { name: "finraDateThree", value: null },
    { name: "finraClientNameOne", value: null },
    { name: "finraClientNameTwo", value: null },
    { name: "finraClientNameThree", value: null },
    { name: "finraSizeOne", value: null },
    { name: "finraSizeTwo", value: null },
    { name: "finraSizeThree", value: null },
    { name: "finraIssuerNameOne", value: null },
    { name: "finraIssuerNameTwo", value: null },
    { name: "finraIssuerNameThree", value: null },
    { name: "finra", value: null },
    { name: "blueSky", value: null },
    { name: "blueSkyExplanation", value: null },
    { name: "exemptOfferings", value: null },
    { name: "exemptOfferingsIssuerClientsNumberTransactions", value: null },
    { name: "exemptOfferingsIssuerClientsDollarAmount", value: null },
    { name: "exemptOfferingsUnderwriterNumberTransactions", value: null },
    { name: "exemptOfferingsUnderwriterDollarAmount", value: null },
    { name: "exemptOfferingsInvestorDollarAmount", value: null },
    { name: "exemptOfferingsInvestorNumberTransactions", value: null },
    { name: "obligationsProxyStatements", value: null },
    { name: "obligationsProxyStatementsExplanation", value: null },
    { name: "investmentCompanyAct", value: null },
    { name: "investmentCompanyActExplanation", value: null },
    { name: "outsideUS", value: null },
    { name: "outsideUSExplanation", value: null },
    { name: "assetBacked", value: null },
    { name: "assetBackedExplanation", value: null },
    { name: "pennyStocks", value: null },
    { name: "transferAgent", value: null },
    { name: "transferAgentPublicCompanies", value: null },
    { name: "escrowAgent", value: null },
    { name: "stateRegistration", value: null },
    { name: "signDate", value: null },
    { name: "signature", value: null },
  ],
}

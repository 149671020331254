<template>
  <div id="formState" class="form-state-select">
    <Select
      :value="value"
      :label="label"
      full-width
      attach
      :items="attorneyOptions"
      :required="required"
      :loading="loading"
      :multiple="multiple"
      @input="handleInput($event)"
    />
  </div>
</template>

<script>
import Select from "@/components/Select.vue"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { getPolicyAttorneysInsured, getAllPoliciesByClientKey } from "@/services/policyService"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"

export default {
  components: { Select },
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "State",
    },
    required: {
      type: Boolean,
      default: null,
    },
    withBarNumbers: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    attorneysInsured: [],
    attorneyOptions: [],
  }),
  mounted() {
    this.populateAttorneys()
  },
  methods: {
    async populateAttorneys() {
      await safelyDoWithUserInfo(async user => {
        const activePolicies = await getAllPoliciesByClientKey(getClientKeyFromUserOrUrl(this, user))
        const { policyKey } = activePolicies[0]

        this.attorneysInsured = await getPolicyAttorneysInsured(
          getClientKeyFromUserOrUrl(this, user),
          policyKey,
          this.withBarNumbers
        )

        this.attorneyOptions = this.attorneysInsured.map(attorney => ({ text: attorney.name, value: attorney.name }))
        this.loading = false
      })
    },
    handleInput(value) {
      this.$emit("input", value)

      if (this.multiple === null && this.withBarNumbers) {
        const attorneySelected = this.attorneysInsured.find(attorney => attorney.name === value)
        this.$emit("bar-number-selected", attorneySelected.barNumber)
      }

      const formattedValue = value.length === 0 ? null : value.join(", ")

      this.$emit("input-formatted", formattedValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.form-state-select {
  border-radius: 0 !important;
  width: 100%;
}

::v-deep {
  .v-select__selection--comma {
    color: var(--dark-grey) !important;
  }
  .v-list-item {
    min-height: 3.6rem;
  }
  .select-item {
    font-size: 1.2rem !important;
  }
}
</style>

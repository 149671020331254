<template>
  <div class="lmnc-policy-item">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.lmnc-policy-item {
  font-size: 2.2rem;
  font-weight: 400;
  color: var(--dark-grey);
  margin: 0;
  padding-top: 0.5rem;
}
</style>

export const policyFormSubmittedStore = {
  state: {
    policyFormSubmitted: false,
    formName: "" as string | undefined,
  },

  setPolicyFormSubmitted(policyFormSubmitted: boolean) {
    this.state.policyFormSubmitted = policyFormSubmitted
  },

  setPolicyFormName(formName: string) {
    this.state.formName = formName
  },
}


















































































































































import Vue, { VueConstructor } from "vue"
import { DOCUMENT_CATEGORY_KEY } from "@/constants/categoryKey"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { AutofillAddress } from "@/mixins/autoFillAddress"
import { businessNameChange } from "@/constants/policyChangeForms"
import Form from "../Forms/Form.vue"
import FormSection from "../Forms/FormSection.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import FormRow from "../Forms/FormRow.vue"
import TextInput from "../../TextInput.vue"
import FormStateSelect from "../Forms/FormStateSelect.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import DocUploader from "../Forms/DocUploader.vue"
import FormContent from "../Forms/FormContent.vue"
import FormBody from "../Forms/FormBody.vue"
import FormDetail from "../Forms/FormDetail.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    FormStateSelect,
    FormDatePicker,
    DocUploader,
    FormContent,
    FormBody,
    FormDetail,
  },
  mixins: [
    FormSubmissionMixin,
    AutofillFirmInfoMixin(false, "priorfirmname"),
    AutofillAddress({
      addressPropertyName: "newstreetorpobox",
      cityPropertyName: "newcity",
      statePropertyName: "newstate",
      zipPropertyName: "newzip",
    }),
  ],
  data: () => ({
    formToSubmit: businessNameChange,
    userSubmissionForm: {
      type: "businessNameChange",
      description: "Change of Business Name",
      items: {
        priorfirmname: null,
        policykey: null,
        newfirmname: null,
        newstreetorpobox: null,
        newcity: null,
        newstate: null,
        newzip: null,
        effectivedate: null,
        firmwebsite: null,
        signature: {
          partnersigndate: null,
          partnersignature: null,
        },
      },
    },
    userSubmissionDocuments: {
      letterhead: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
    },
  }),
})

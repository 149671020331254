




























import Vue from "vue"
import LmncDialog from "@/components/LmncDialog.vue"
import LmncSnackbar from "@/components/LmncSnackbar.vue"
import Button from "@/components/Button.vue"

export default Vue.extend({
  components: {
    LmncDialog,
    LmncSnackbar,
    Button,
  },
  props: {
    itemName: {
      type: String,
      required: true,
    },
    showSubmissionDialog: {
      type: Boolean,
      required: true,
    },
    submittingMessage: {
      type: Boolean,
      required: true,
    },
    messageSubmissionErrorOccurred: {
      type: Boolean,
      required: true,
    },
    showSignatureErrorSnackbar: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleSubmissionCancelled() {
      this.$emit("submission-cancelled")
    },
    handleSignatureErrorSnackbarClosed() {
      this.$emit("signature-error-snackbar-closed")
    },
    handleSendMessage() {
      this.$emit("send-message")
    },
  },
})
















import Vue from "vue"
import LawyersMutualLogo from "./LawyersMutualLogo.vue"

export default Vue.extend({
  components: {
    LawyersMutualLogo,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
})

import { AttorneyInsured } from "@/types/AttorneyInsured"
import { Policy } from "@/types/Policy"
import { PolicyDocument } from "@/types/PolicyDocument"
import { PolicyInfo } from "@/types/PolicyInfo"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getClientsUrl, getDocumentsUrl } from "@/util/urls"

export const getAllPoliciesByClientKey = async (clientKey: string) =>
  makeAuthenticatedRequest<Policy[]>(`${getClientsUrl()}/${clientKey}/active-policies`, "GET")

export const getPolicyInfo = async (clientKey: string, policyKey: string) =>
  makeAuthenticatedRequest<PolicyInfo>(`${getClientsUrl()}/${clientKey}/active-policies/${policyKey}`, "GET")

export const getPolicyAttorneysInsured = async (clientKey: string, policyKey: string, withBarNumber = false) =>
  makeAuthenticatedRequest<AttorneyInsured[]>(
    `${getClientsUrl()}/${clientKey}/active-policies/${policyKey}/attorneys-insured${
      withBarNumber ? "?withBarNumber=true" : ""
    }`,
    "GET"
  )

export const getPolicyDocuments = async (clientKey: string, policyKey: string) =>
  makeAuthenticatedRequest<PolicyDocument[]>(`${getDocumentsUrl()}/${clientKey}/policy-documents/${policyKey}`, "GET")

export const getDocumentContent = async (clientKey: string, documentKey: string) =>
  makeAuthenticatedRequest<string>(
    `${getDocumentsUrl()}/${clientKey}/${documentKey}`,
    "GET",
    undefined,
    false,
    "arraybuffer"
  )

<template>
  <div class="user-profile">
    <div>
      <UserInformationTemplate
        :selected-user="currentUserState.user"
        title="Profile"
        submit-label="Save"
        :is-user-profile="true"
        @back="handleCancelClick"
        @submit="handleUpdateUser"
        @error="handleSubmissionResponse"
      >
      </UserInformationTemplate>
    </div>
    <LmncSnackbar :value="isSnackbarOpen" :text="resultText" :variety="resultVariety" @close="handleSnackbarClose" />
  </div>
</template>

<script>
import UserInformationTemplate from "@/components/LMNCAdmin/UserInformationTemplate.vue"
import { currentUserStateStore } from "@/stores/currentUserStore"
import LmncSnackbar from "@/components/LmncSnackbar.vue"
import { updateClientUserById } from "@/services/clientService"
import { normalizePhoneNumber } from "@/util/normalizePhoneNumber"

export default {
  components: { UserInformationTemplate, LmncSnackbar },
  props: {
    isUser: {
      type: Boolean,
      default: null,
    },
    isClientAdmin: {
      type: Boolean,
      default: null,
    },
    isLmncAdmin: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    isSnackbarOpen: false,
    resultText: null,
    resultVariety: null,
    currentUserState: currentUserStateStore.state,
  }),
  methods: {
    handleSubmissionResponse(value) {
      this.isLoading = true
      this.resultText = value.text
      this.resultVariety = value.variety
      this.isSnackbarOpen = true
      this.isLoading = false
    },

    async handleUpdateUser(update) {
      this.isLoading = true
      try {
        const normalizedPhoneNumber = normalizePhoneNumber(update.phone)

        const patchPayload = { ...update, phone: normalizedPhoneNumber }

        if (!this.isLmncAdmin) {
          delete patchPayload.isClientAdmin
        }

        await updateClientUserById(this.currentUserState.user.id, patchPayload)

        const updatedUser = {
          ...this.currentUserState.user,
          firstName: update.firstName,
          lastName: update.lastName,
          phone: normalizedPhoneNumber,
          isClientAdmin: update.isClientAdmin || false,
        }

        currentUserStateStore.setCurrentUser(updatedUser)

        this.handleSubmissionResponse({ text: `Updated ${update.firstName} ${update.lastName}`, variety: "success" })
        this.isLoading = false
        this.routeBasedOnRole()
      } catch (error) {
        this.handleSubmissionResponse({ text: `Something went wrong`, variety: "error" })
        this.isLoading = false
      }
    },

    handleSnackbarClose() {
      this.isSnackbarOpen = false
    },

    handleCancelClick() {
      this.routeBasedOnRole()
    },

    routeBasedOnRole() {
      if (this.isUser) {
        this.$router.push("/policies")
      }
      if (this.isClientAdmin) {
        this.$router.push(`/firms/${this.currentUserState.user.clientKey}`)
      }
      if (this.isLmncAdmin) {
        this.$router.push("/firms")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-profile {
  height: 100%;
  min-height: 75rem;
  width: 100%;
}

.loading-page-spinner {
  height: 27rem;
  width: 100%;
}
</style>

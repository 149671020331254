
















































































































































































































import { UserRole } from "@/constants/userRoles"
import { currentUserStateStore } from "@/stores/currentUserStore"
import Vue from "vue"
import FAQAccordion from "../components/FAQ/FAQAccordion.vue"

export default Vue.extend({
  components: {
    FAQAccordion,
  },
  data() {
    return {
      extendedReportingEndorsementDialogOpen: false,
      currentUser: currentUserStateStore.state.user,
    }
  },
  computed: {
    manageUsersUrl(): string {
      return `/firms/${this.currentUser?.clientKey}`
    },
    addUserUrl(): string {
      return `${this.manageUsersUrl}/add-user`
    },
    isClientAdmin(): boolean {
      return this.currentUser?.role === UserRole.CLIENT_ADMIN
    },
  },
})

import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import { UserRecord } from "@/types/UserRecord"
import RoleBasedDashboard from "@/components/dashboard/RoleBasedDashboard.vue"
import LmncAdminDashboard from "@/components/dashboard/LmncAdminDashboard.vue"
import FirmsTable from "@/views/FirmsTable.vue"
import FirmUsersTable from "@/views/FirmUsersTable.vue"
import EditFirmUser from "@/views/EditFirmUser.vue"
import AddFirmUser from "@/views/AddFirmUser.vue"
import UserDashboard from "@/components/dashboard/UserDashboard.vue"
import UserProfile from "@/views/UserProfile.vue"
import { UserRole } from "@/constants/userRoles"
import { fetchUserInformation } from "@/services/authServices"
import FirmDashboard from "@/views/FirmDashboard.vue"
import { firmBannerStore } from "@/stores/firmBannerStore"
import { isAdminClientViewUrl } from "@/constants/adminDashboardPath"
import AdminFirmUsers from "@/views/AdminFirmUsers.vue"
import Policies from "../views/Policies.vue"
import PolicyDashboard from "../components/Policies/PolicyDashboard/PolicyDashboard.vue"
import YourPolicy from "../components/Policies/YourPolicy.vue"
import QuotesInvoices from "../components/Policies/QuotesInvoices.vue"
import QuoteDocuments from "../components/Policies/QuoteDocuments.vue"
import QuotesTable from "../components/Policies/QuotesTable.vue"
import PolicyDocuments from "../components/Policies/PolicyDocuments.vue"
import MakeAPolicyChange from "../components/Policies/MakeAPolicyChange.vue"
import AddALawyer from "../components/Policies/PolicyChanges/AddALawyer.vue"
import RemoveALawyer from "../components/Policies/PolicyChanges/RemoveALawyer.vue"
import ChangeAddress from "../components/Policies/PolicyChanges/ChangeAddress.vue"
import ChangeBusinessName from "../components/Policies/PolicyChanges/ChangeBusinessName.vue"
import PolicyCancellation from "../components/Policies/PolicyChanges/PolicyCancellation.vue"
import RequestEreQuotes from "../components/Policies/PolicyChanges/RequestEreQuotes.vue"
import RequestMidTermLimitChange from "../components/Policies/PolicyChanges/RequestMidTermLimitChange.vue"
import AttorneyNameChange from "../components/Policies/PolicyChanges/AttorneyNameChange.vue"
import PolicyApplications from "../components/Policies/PolicyApplications.vue"
import EstatesTrusts from "../components/Policies/PolicyApplications/Estates&Trusts.vue"
import IntellectualProperty from "../components/Policies/PolicyApplications/IntellectualProperty.vue"
import MergersAcquisitions from "../components/Policies/PolicyApplications/Mergers&Acquisitions.vue"
import PlantiffPersonalInjury from "../components/Policies/PolicyApplications/PlantiffPersonalInjury.vue"
import RealEstate from "../components/Policies/PolicyApplications/RealEstate.vue"
import Securities from "../components/Policies/PolicyApplications/Securities.vue"
import Billing from "../views/Billing.vue"
import Invoice from "../components/Billing/Invoice.vue"
import PaymentMethods from "../components/Billing/PaymentMethods.vue"
import AutoPay from "../components/Billing/AutoPay.vue"
import Paperless from "../components/Billing/Paperless.vue"
import PayByText from "../components/Billing/PayByText.vue"
import FAQ from "../views/FAQ.vue"
import Contact from "../views/Contact.vue"
import UpdateContactInfo from "../views/UpdateContactInfo.vue"
import ConfirmationOfInsurance from "../views/ConfirmationOfInsurance.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "RoleBasedDashboard",
    component: RoleBasedDashboard,
    children: [
      {
        path: "/policies",
        component: UserDashboard,
        children: [
          {
            path: "",
            component: PolicyDashboard,
          },
          {
            path: "/policies/:policyKey",
            component: Policies,
            children: [
              {
                path: "",
                component: YourPolicy,
                props: true,
              },
              {
                path: "policy-documents",
                component: PolicyDocuments,
              },
              {
                path: "policy-change",
                component: MakeAPolicyChange,
                children: [
                  {
                    path: "add-attorney",
                    name: "AddALawyer",
                    component: AddALawyer,
                  },
                  {
                    path: "remove-attorney",
                    name: "RemoveALawyer",
                    component: RemoveALawyer,
                  },
                  {
                    path: "change-address",
                    name: "ChangeAddress",
                    component: ChangeAddress,
                  },
                  {
                    path: "change-business-name",
                    name: "ChangeBusinessName",
                    component: ChangeBusinessName,
                  },
                  {
                    path: "policy-cancellation",
                    name: "PolicyCancellation",
                    component: PolicyCancellation,
                  },
                  {
                    path: "request-ere-quote",
                    name: "RequestEreQuotes",
                    component: RequestEreQuotes,
                  },
                  {
                    path: "request-mid-change",
                    name: "RequestMidTermLimitChange",
                    component: RequestMidTermLimitChange,
                  },
                  {
                    path: "attorney-name-change",
                    name: "AttorneyNameChange",
                    component: AttorneyNameChange,
                  },
                ],
              },
              {
                path: "quotes",
                component: QuotesInvoices,
                children: [
                  {
                    path: "",
                    name: "QuotesTable",
                    component: QuotesTable,
                    props: true,
                  },
                  {
                    path: ":quoteKey/:quoteVersion",
                    name: "QuoteDocuments",
                    component: QuoteDocuments,
                    props: true,
                  },
                ],
              },
            ],
          },
          {
            path: "/billing",
            component: Billing,
            children: [
              {
                path: "",
                name: "Billing",
                component: Invoice,
              },
              {
                path: "payment-methods",
                component: PaymentMethods,
              },
              {
                path: "auto-pay",
                component: AutoPay,
              },
              {
                path: "paperless",
                component: Paperless,
              },
              {
                path: "pay-by-text",
                component: PayByText,
              },
            ],
          },
          {
            path: "/applications",
            component: PolicyApplications,
            children: [
              {
                path: "",
                name: "EstatesTrusts",
                component: EstatesTrusts,
              },
              {
                path: "intellectual-property",
                name: "IntellectualProperty",
                component: IntellectualProperty,
              },
              {
                path: "mergers-acquisitions",
                name: "MergersAcquisitions",
                component: MergersAcquisitions,
              },
              {
                path: "plaintiff-personal-injury",
                name: "PlantiffPersonalInjury",
                component: PlantiffPersonalInjury,
              },
              {
                path: "real-estate",
                name: "RealEstate",
                component: RealEstate,
              },
              {
                path: "securities",
                name: "Securities",
                component: Securities,
              },
            ],
          },
          {
            path: "/faq",
            name: "FAQ",
            component: FAQ,
          },
          {
            path: "/contact",
            name: "Contact",
            component: Contact,
          },
        ],
      },
      {
        path: "/update-contact-info",
        name: "Update Contact Info",
        component: UpdateContactInfo,
      },
      {
        path: "/confirmation-of-insurance",
        name: "Confirmation of Insurance",
        component: ConfirmationOfInsurance,
      },
      {
        path: "/firms/:clientKey",
        name: "FirmUsersTable",
        component: FirmUsersTable,
      },
      {
        path: "/firms/:clientKey/users/:firmUserId",
        name: "EditFirmUser",
        component: EditFirmUser,
      },
      {
        path: "/firms/:clientKey/add-user",
        name: "AddFirmUser",
        component: AddFirmUser,
      },
      {
        path: "/firms",
        component: LmncAdminDashboard,
        children: [
          {
            path: "/",
            name: "FirmsTable",
            component: FirmsTable,
          },
          {
            path: "/firms/:clientKey/dashboard",
            component: FirmDashboard,
            children: [
              {
                path: "users",
                component: AdminFirmUsers,
                children: [
                  {
                    path: "",
                    name: "AdminFirmUsers",
                    component: FirmUsersTable,
                  },
                  {
                    path: "add-user",
                    name: "AddFirmUser",
                    component: AddFirmUser,
                  },
                  {
                    path: ":firmUserId",
                    name: "EditFirmUser",
                    component: EditFirmUser,
                  },
                ],
              },
              {
                path: "policies",
                component: UserDashboard,
                children: [
                  {
                    path: "",
                    component: PolicyDashboard,
                  },
                  {
                    path: ":policyKey",
                    component: Policies,
                    children: [
                      {
                        path: "",
                        component: YourPolicy,
                        props: true,
                      },
                      {
                        path: "policy-documents",
                        component: PolicyDocuments,
                      },
                      {
                        path: "policy-change",
                        component: MakeAPolicyChange,
                        children: [
                          {
                            path: "add-attorney",
                            name: "AdminAddALawyer",
                            component: AddALawyer,
                          },
                          {
                            path: "remove-attorney",
                            name: "AdminRemoveALawyer",
                            component: RemoveALawyer,
                          },
                          {
                            path: "change-address",
                            name: "AdminChangeAddress",
                            component: ChangeAddress,
                          },
                          {
                            path: "change-business-name",
                            name: "AdminChangeBusinessName",
                            component: ChangeBusinessName,
                          },
                          {
                            path: "policy-cancellation",
                            name: "AdminPolicyCancellation",
                            component: PolicyCancellation,
                          },
                          {
                            path: "request-ere-quote",
                            name: "AdminRequestEreQuotes",
                            component: RequestEreQuotes,
                          },
                          {
                            path: "request-mid-change",
                            name: "AdminRequestMidTermLimitChange",
                            component: RequestMidTermLimitChange,
                          },
                          {
                            path: "attorney-name-change",
                            name: "AdminAttorneyNameChange",
                            component: AttorneyNameChange,
                          },
                        ],
                      },
                      {
                        path: "quotes",
                        component: QuotesInvoices,
                        children: [
                          {
                            path: "",
                            name: "AdminQuotesTable",
                            component: QuotesTable,
                            props: true,
                          },
                          {
                            path: ":quoteKey/:quoteVersion",
                            name: "AdminQuoteDocuments",
                            component: QuoteDocuments,
                            props: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "billing",
                component: Billing,
                children: [
                  {
                    path: "",
                    name: "AdminBilling",
                    component: Invoice,
                  },
                  {
                    path: "payment-methods",
                    component: PaymentMethods,
                  },
                  {
                    path: "auto-pay",
                    component: AutoPay,
                  },
                  {
                    path: "paperless",
                    component: Paperless,
                  },
                  {
                    path: "pay-by-text",
                    component: PayByText,
                  },
                ],
              },
              {
                path: "faq",
                name: "AdminFAQ",
                component: FAQ,
              },
              {
                path: "contact",
                name: "AdminContact",
                component: Contact,
              },
              {
                path: "applications",
                component: PolicyApplications,
                children: [
                  {
                    path: "",
                    name: "AdminEstatesTrusts",
                    component: EstatesTrusts,
                  },
                  {
                    path: "intellectual-property",
                    name: "AdminIntellectualProperty",
                    component: IntellectualProperty,
                  },
                  {
                    path: "mergers-acquisitions",
                    name: "AdminMergersAcquisitions",
                    component: MergersAcquisitions,
                  },
                  {
                    path: "plaintiff-personal-injury",
                    name: "AdminPlantiffPersonalInjury",
                    component: PlantiffPersonalInjury,
                  },
                  {
                    path: "real-estate",
                    name: "AdminRealEstate",
                    component: RealEstate,
                  },
                  {
                    path: "securities",
                    name: "AdminSecurities",
                    component: Securities,
                  },
                ],
              },
              {
                path: "update-contact-info",
                name: "AdminUpdate Contact Info",
                component: UpdateContactInfo,
              },
              {
                path: "confirmation-of-insurance",
                name: "AdminConfirmation of Insurance",
                component: ConfirmationOfInsurance,
              },
            ],
          },
        ],
      },
      {
        path: "/profile",
        name: "UserProfile",
        component: UserProfile,
      },
    ],
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

const ROLE_HOME_MAP = new Map([
  [UserRole.LMNC_ADMIN, () => "/firms"],
  [UserRole.CLIENT_ADMIN, (user: UserRecord) => `/firms/${user.clientKey}`],
  [UserRole.USER, () => "policies"],
])

router.beforeEach(async (to, from, next) => {
  if (to.path === "/") {
    const currentUser = await fetchUserInformation()

    const homeUrlFactory = ROLE_HOME_MAP.get(currentUser.role)

    if (typeof homeUrlFactory === "undefined") {
      throw new Error(`No home page found for user role '${currentUser.role}'`)
    }

    return next(homeUrlFactory(currentUser))
  }

  if (isAdminClientViewUrl(to.path)) {
    firmBannerStore.showBanner()
  } else {
    firmBannerStore.hideBanner()
  }

  return next()
})

router.afterEach(() => {
  window.scrollTo({
    top: 0,
  })
})

export default router









import Vue from "vue"
import Button from "@/components/Button.vue"

export default Vue.extend({
  components: { Button },
  data: () => ({
    viewContentOpen: false,
  }),
  computed: {
    buttonText(): string {
      return this.viewContentOpen ? "View Less" : "View All"
    },
  },
  methods: {
    handleClick($event: Event) {
      this.viewContentOpen = !this.viewContentOpen
      this.$emit("click", $event)
    },
  },
})

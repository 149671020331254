













import Vue from "vue"
import Loader from "../Loader.vue"

export default Vue.extend({
  components: { Loader },
  props: {
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
































































































import Vue from "vue"
import { required } from "vuelidate/lib/validators"
import { denormalizePhoneNumber } from "@/util/normalizePhoneNumber"
import { validatePhoneNumber } from "@/validators/validatePhoneNumber"
import { currentUserStateStore } from "@/stores/currentUserStore"
import { SelectItem } from "@/types/SelectItem"
import Form from "@/components/Policies/Forms/Form.vue"
import FormContent from "@/components/Policies/Forms/FormContent.vue"
import FormSectionContent from "@/components/Policies/Forms/FormSectionContent.vue"
import FormSection from "@/components/Policies/Forms/FormSection.vue"
import FormRow from "@/components/Policies/Forms/FormRow.vue"
import LmncSnackbar from "@/components/LmncSnackbar.vue"
import { Contact } from "@/types/Contact"
import { getAllContacts, sendContactMessage } from "@/services/contactsService"
import { CONTACT_TYPE_CLAIMS_ATTORNEY, CONTACT_TYPE_OTHER } from "@/constants/contactTypes"
import Select from "../components/Select.vue"
import TextInput from "../components/TextInput.vue"
import TextArea from "../components/TextArea.vue"

export default Vue.extend({
  components: {
    TextInput,
    TextArea,
    Select,
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    FormContent,
    LmncSnackbar,
  },
  data() {
    return {
      phoneNumber: "",
      name: "",
      recipient: "",
      message: "",
      recipients: null as null | SelectItem[],
      isSendingMessage: false,
      selectedContactId: "",
      isSnackbarOpen: false,
      errorText: "",
      currentUserState: currentUserStateStore.state,
      isErrorSnackbarOpen: false,
    }
  },
  computed: {
    isLoading(): boolean {
      return this.recipients === null
    },
    phoneErrorMessages(): string[] {
      if (!this.$v.phoneNumber.$dirty) {
        return []
      }

      if (!this.$v.phoneNumber.required) {
        return ["Phone number is required"]
      }

      if (!this.$v.phoneNumber.validatePhoneNumber) {
        return ["Please enter a ten digit phone number"]
      }

      return []
    },
    selectedContactIdErrorMessages(): string[] {
      if (!this.$v.selectedContactId.$dirty) {
        return []
      }

      if (!this.$v.selectedContactId.required) {
        return ["Please select a contact"]
      }

      return []
    },
    messageErrorMessages(): string[] {
      if (!this.$v.message.$dirty) {
        return []
      }

      if (!this.$v.message.required) {
        return ["Please enter a message"]
      }

      return []
    },
  },
  mounted() {
    if (currentUserStateStore.state.user !== null) {
      if (!currentUserStateStore.state.user.firstName && !currentUserStateStore.state.user.lastName) {
        this.name = "N/A"
      } else {
        this.name = `${currentUserStateStore.state.user.firstName!} ${currentUserStateStore.state.user.lastName!}`
      }

      this.phoneNumber = denormalizePhoneNumber(currentUserStateStore.state.user.phone!)
    }

    this.fetchContacts()
  },
  validations: {
    phoneNumber: {
      required,
      validatePhoneNumber,
    },
    message: {
      required,
    },
    selectedContactId: {
      required,
    },
  },
  methods: {
    async handleContactSubmit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.isSnackbarOpen = false
        this.isSendingMessage = true
        this.isErrorSnackbarOpen = false

        try {
          await sendContactMessage(this.selectedContactId, this.phoneNumber, this.name, this.message)
          this.isSnackbarOpen = true
        } catch (error) {
          this.isErrorSnackbarOpen = true
        }

        this.isSendingMessage = false
      }
    },
    mapContactsToOptions(contacts: Contact[]) {
      return contacts.map((item: Contact) => ({
        value: item.id,
        text: item.displayName,
        subItem: item.type === CONTACT_TYPE_CLAIMS_ATTORNEY,
      }))
    },
    async fetchContacts() {
      const contacts = await getAllContacts()

      const claimsAttorneys = contacts.filter(contact => contact.type === CONTACT_TYPE_CLAIMS_ATTORNEY)
      const otherContacts = contacts.filter(contact => contact.type === CONTACT_TYPE_OTHER)

      this.recipients = [
        ...this.mapContactsToOptions(otherContacts),
        { text: "Claims Attorneys", header: "Claims Attorneys" },
        ...this.mapContactsToOptions(claimsAttorneys),
      ] as SelectItem[]
    },
  },
})























import { DEFAULT_PAGE_SIZE } from "@/constants/defaultPageSize"
import { getAllQuotesByClientKey } from "@/services/quotesServices"
import { currentUserStateStore } from "@/stores/currentUserStore"
import { Quote } from "@/types/Quote"
import Vue from "vue"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import { UserRole } from "@/constants/userRoles"
import LmncTable from "../LmncTable.vue"

export default Vue.extend({
  components: { LmncTable },
  data() {
    return {
      quotes: null as null | Quote[],
      headers: [
        {
          text: "Quote Key",
          value: "quoteKey",
          sortable: "false",
        },
      ],
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalPages: 1,
      totalNumberOfQuotes: 3,
      userStateStore: currentUserStateStore.state,
      quoteLink: "QuoteDocuments",
    }
  },
  computed: {
    isLoading(): boolean {
      return this.quotes === null
    },
  },
  async mounted() {
    await safelyDoWithUserInfo(async user => {
      if (user.role === UserRole.LMNC_ADMIN) {
        this.quoteLink = "AdminQuoteDocuments"
      }
    })

    this.fetchQuotes()
  },
  methods: {
    async fetchQuotes() {
      await safelyDoWithUserInfo(async user => {
        const response = await getAllQuotesByClientKey(
          getClientKeyFromUserOrUrl(this, user),
          this.pageNumber,
          this.pageSize
        )

        this.quotes = response.items
        this.totalNumberOfQuotes = response.total
        this.totalPages = Math.ceil(response.total / this.pageSize)
      })
    },
    handlePageChange(pageNumber: number) {
      this.pageNumber = pageNumber
    },
  },
})

import Keycloak from "keycloak-js"
import getEnv from "@/util/env"

const keycloakConfig = {
  url: getEnv("VUE_APP_KEYCLOAK_HOST_URL") as string,
  realm: getEnv("VUE_APP_KEYCLOAK_REALM_NAME") as string,
  clientId: getEnv("VUE_APP_KEYCLOAK_CLIENT_ID") as string,
}

let keycloakInstance: Keycloak | null = null

export const configKeycloak = () => {
  if (keycloakInstance === null) {
    keycloakInstance = new Keycloak(keycloakConfig)
  } else {
    // eslint-disable-next-line no-console
    console.warn("An attempt was made to initialize Keycloak, but it has already been initialized")
  }
}

export const getKeycloak = () => {
  if (keycloakInstance == null) {
    throw new Error(
      "An attempt was made to utilize keycloak before it was configured -- be sure to call configKeycloak first"
    )
  }

  return keycloakInstance
}
























import Vue, { VueConstructor } from "vue"
import { DEFAULT_PAGE_SIZE } from "@/constants/defaultPageSize"
import { getPolicyDocuments } from "@/services/policyService"
import { PolicyDocument } from "@/types/PolicyDocument"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import LmncTable from "@/components/LmncTable.vue"
import DownloadRow from "@/components/DownloadRow.vue"
import { DocumentDownloadMixin } from "@/mixins/documentDownload"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"

export default (Vue as VueConstructor<Vue & typeof DocumentDownloadMixin>).extend({
  components: {
    LmncTable,
    DownloadRow,
  },
  mixins: [DocumentDownloadMixin],
  props: {
    policyKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documents: null as null | PolicyDocument[],
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      headers: [
        {
          text: "Document Key",
          value: "documentKey",
        },
      ],
    }
  },
  computed: {
    isLoading(): boolean {
      return this.documents === null
    },
  },
  mounted() {
    this.fetchDocuments()
  },
  methods: {
    async fetchDocuments() {
      await safelyDoWithUserInfo(async user => {
        this.documents = await getPolicyDocuments(getClientKeyFromUserOrUrl(this, user), this.policyKey)
      })
    },
    handlePageChange(pageNumber: number) {
      this.pageNumber = pageNumber
    },
  },
})

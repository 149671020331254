






import Vue from "vue"

export default Vue.extend({
  props: {
    pTop: {
      type: Boolean,
      default: null,
    },
    pRight: {
      type: Boolean,
      default: null,
    },
    pBtm: {
      type: Boolean,
      default: null,
    },
    pLeft: {
      type: Boolean,
      default: null,
    },
    noGutterRow: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    addedPadding() {
      return `padding: ${this.pTop ? "1.5rem" : "0rem"} ${this.pRight ? "1.5rem" : "0rem"} ${
        this.pBtm ? "1.5rem" : "0rem"
      }  ${this.pLeft ? "1.5rem" : "0rem"}`
    },
  },
})

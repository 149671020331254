import axios from "axios"
import jwt, { JwtPayload } from "jsonwebtoken"
import { UserRecord } from "@/types/UserRecord"
import { InvalidCredentialsError } from "@/util/errors"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getUserUrl } from "@/util/urls"
import { getKeycloak } from "@/keycloak/keycloak"
import { EmailInUse } from "@/types/EmailInUseResponse"
import { UserRole } from "@/constants/userRoles"

export const fetchUserById = async (userId: string): Promise<UserRecord | null> => {
  try {
    const response: UserRecord = await makeAuthenticatedRequest(`${getUserUrl()}/${userId}`, "GET")
    return response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new InvalidCredentialsError("Unable to fetch User.")
    }
    return null
  }
}

// We must disable the camelcase rule because keycloak sets the name of this property in the token
// eslint-disable-next-line camelcase
type JwtKeycloakPayload = JwtPayload & { realm_access: { roles: string[] } }

export const fetchUserInformation = async (): Promise<UserRecord> => {
  const userToken = getKeycloak().token

  if (typeof userToken === "undefined") {
    throw new Error("No token found for user")
  }

  const user = jwt.decode(userToken) as unknown as JwtKeycloakPayload

  if (user === null || typeof user.sub === "undefined") {
    throw new Error("The user's token could not be decoded")
  }

  if (user.realm_access.roles.includes(UserRole.LMNC_ADMIN)) {
    return { id: user.sub, clientKey: null, email: user.email, role: UserRole.LMNC_ADMIN, isClientAdmin: true }
  }

  const userRecord = await fetchUserById(user.sub)

  if (userRecord === null) {
    throw new Error(`No user record found for user with id ${user.sub}`)
  }

  return userRecord
}

export const fetchEmailInUse = async (email: string): Promise<EmailInUse> =>
  makeAuthenticatedRequest(`${getUserUrl()}/email-addresses/${encodeURIComponent(email)}`, "GET")

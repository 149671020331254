<template>
  <div class="firms-table-view">
    <div class="firms-table-content">
      <div class="firms-table-title">
        <h1>Firms</h1>
        <TextInput
          v-model="search"
          class="search-input"
          label="Search"
          underlined
          animate-label
          search-icon
          allow-clear
          @input="debouncedFirmSearch"
        />
      </div>
      <v-divider></v-divider>
      <LmncTable
        :headers="headers"
        :items="firmsData"
        item-key="clientKey"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total-pages="totalPages"
        :sort-by="sortBy"
        :sort-desc="sortDirection === 'DESC'"
        :total-count="totalFirms"
        :loading="isLoading"
        @page-change="handlePageChange"
        @sort-by-change="handleSortByChange"
        @sort-desc-change="handleSortDescChange"
      >
        <template #item.name="{ item }">
          <router-link :to="`/firms/${item.clientKey}/dashboard/policies`">
            <HighlightedText :text="item.name" :search="searchToHighlight" />
          </router-link>
        </template>
        <template #item.userCount="{ item }">
          {{ `${item.userCount} ${pluralizeIfNeeded("User", item.userCount)}` }}
        </template>
        <template #item.action="{ item }">
          <YourPolicyBtn icon="edit" font-size="1.4rem" @click="handleEdit(item.clientKey, item.name)"
            >Edit</YourPolicyBtn
          >
        </template>
      </LmncTable>
    </div>
  </div>
</template>

<script>
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import LmncTable from "@/components/LmncTable.vue"
import { SortDirection } from "@/constants/sortDirection"
import { DEFAULT_PAGE_SIZE } from "@/constants/defaultPageSize"
import YourPolicyBtn from "@/components/Policies/YourPolicyInformation/YourPolicyBtn.vue"
import { getAllClients } from "@/services/clientService"
import { PluralizeIfNeededMixin } from "@/mixins/pluralizeIfNeeded"
import TextInput from "@/components/TextInput.vue"
import { clientStore } from "@/stores/clientStore"
import HighlightedText from "@/components/HighlightedText.vue"

const TABLE_SETTINGS_SESSION_STORAGE_KEY = "firmsTableSettings"
const TABLE_SCROLL_SESSION_STORAGE_KEY = "firmsTableScroll"

export default {
  components: { LmncTable, YourPolicyBtn, TextInput, HighlightedText },
  mixins: [PluralizeIfNeededMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRoute = from
      vm.$nextTick(async () => {
        vm.restoreTableSettings()
        await vm.initializeTable()
        vm.restoreScroll()
      })

      window.addEventListener("scroll", vm.storeScroll)
    })
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.storeScroll)
    next()
  },
  props: {
    isLmncAdmin: {
      type: Boolean,
      default: null,
    },
    isClientAdmin: {
      type: Boolean,
      default: null,
    },
    currentUser: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: true,
    errorOccurred: false,
    errorMessage: null,
    firmsData: [],
    hasNextPage: null,
    totalPages: 1,
    totalFirms: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: 1,
    paginated: true,
    sortBy: "name",
    sortDirection: SortDirection.ascending,
    search: "",
    searchToHighlight: "",
    previousRoute: "",
    headers: [
      { text: "Firms", value: "name", sortable: false, class: "firms-header", width: "60%" },
      { text: "Client Key", value: "clientKey", sortable: false, class: "firms-header", width: "8%" },
      {
        text: "User Count",
        value: "userCount",
        sortable: false,
        class: "firms-header users-count",
        width: "17%",
        align: "end",
      },
      { text: "", value: "action", sortable: false, class: "firms-header", width: "15%" },
    ],
    navigatingFromFirmUsersTable: false,
  }),
  created() {
    this.debouncedFirmSearch = debounce(firmName => {
      this.handleSearch(firmName)
    }, DEBOUNCE_INTERVAL)
  },
  mounted() {
    clientStore.setClientInfo("", "")
  },
  methods: {
    storeScroll() {
      if (window.scrollY !== 0) {
        sessionStorage.setItem(TABLE_SCROLL_SESSION_STORAGE_KEY, window.scrollY)
      }
    },
    async initializeTable() {
      if (this.isLmncAdmin) {
        await this.fetchFirmData()
      } else if (this.isClientAdmin) {
        this.$router.push(`/firms/${this.currentUser.clientKey}`)
      } else {
        this.$router.push("/policies")
      }
    },
    storeTableSettings() {
      sessionStorage.setItem(
        TABLE_SETTINGS_SESSION_STORAGE_KEY,
        JSON.stringify({
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          paginated: this.paginated,
          search: this.search,
        })
      )
    },
    checkReturningFromFirmUsersTable() {
      return this.previousRoute.name === "FirmUsersTable"
    },
    restoreTableSettings() {
      if (!this.checkReturningFromFirmUsersTable()) {
        sessionStorage.setItem(TABLE_SETTINGS_SESSION_STORAGE_KEY, null)

        return
      }

      const config = sessionStorage.getItem(TABLE_SETTINGS_SESSION_STORAGE_KEY)

      if (config !== null) {
        const parsedConfig = JSON.parse(config)

        const { pageSize, pageNumber, paginated, sortBy, sortDirection, search } = parsedConfig

        this.pageSize = pageSize
        this.pageNumber = pageNumber
        this.paginated = paginated
        this.sortBy = sortBy
        this.sortDirection = sortDirection
        this.search = search
      }

      sessionStorage.setItem(TABLE_SETTINGS_SESSION_STORAGE_KEY, null)
    },
    restoreScroll() {
      const scrollY = sessionStorage.getItem(TABLE_SCROLL_SESSION_STORAGE_KEY)

      if (scrollY !== null && this.checkReturningFromFirmUsersTable()) {
        window.scrollTo(0, scrollY)
      } else {
        sessionStorage.setItem(TABLE_SCROLL_SESSION_STORAGE_KEY, 0)
      }
    },
    async fetchFirmData() {
      this.isLoading = true

      this.storeTableSettings()

      const response = await getAllClients(this.pageSize, this.pageNumber, this.paginated, this.search)
      this.firmsData = response.items
      this.searchToHighlight = this.search

      this.setFirmDataForPagination(response)

      this.isLoading = false
    },

    setFirmDataForPagination(value) {
      this.hasNextPage = value.hasNextPage
      this.totalFirms = value.total
      this.totalPages = Math.ceil(value.total / this.pageSize)
    },

    roleCheck() {
      if (this.isLmncAdmin === false) {
        this.$router.push("/")
      }
    },

    async handlePageChange(value) {
      this.pageNumber = value
      this.fetchFirmData()
    },

    handleEdit(value, name) {
      clientStore.setClientName(name)
      this.$router.push(`/firms/${value}`)
    },

    async handleSortByChange(sortBy) {
      this.sortBy = sortBy
      this.fetchFirmData()
    },

    async handleSortDescChange(sortDesc) {
      if (sortDesc) {
        this.sortDirection = SortDirection.descending
      } else {
        this.sortDirection = SortDirection.ascending
      }

      this.fetchFirmData()
    },

    async handleSearch(search) {
      this.search = search
      this.pageNumber = 1

      this.fetchFirmData()
    },
  },
}
</script>

<style lang="scss" scoped>
.firms-table-title {
  display: flex;

  h1 {
    margin-right: auto;
  }
}

.firms-table-view {
  height: 100%;
  min-height: 75rem;
  width: 100%;
  padding-top: 6rem;

  .loading-page-spinner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.no-firms {
  height: 100%;
  width: 100%;
  min-height: 40rem;
  font-size: 1.8rem;
  color: var(--primary);
}
</style>

<style lang="scss">
.firms-header {
  font-size: 1.4rem !important;
  font-weight: 400;
  line-height: 1.9rem;
  color: var(--dark-grey) !important;

  i {
    font-size: 1.4rem !important;
  }

  span {
    padding-right: 0.5rem !important;
  }
}

.firms-body-row {
  font-size: 2.2rem !important;
  font-weight: 300;
  line-height: 3.2rem;
  color: var(--dark-grey);
  height: 5.3rem !important;

  .total-users {
    color: var(--grey) !important;
    width: 20%;
    font-size: 1.6rem !important;
  }
}

.firms-action-btns {
  width: 10%;
}

.search-input {
  width: 30rem;
}
</style>

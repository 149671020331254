

































import Vue from "vue"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import Button from "../Button.vue"

export default Vue.extend({
  components: { Button },
  props: {
    compact: {
      type: Boolean,
      default: null,
    },
    balance: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showMakePaymentButton(): boolean {
      return this.balance !== 0
    },
    formattedBalance(): string {
      return this.balance.toFixed(2)
    },
  },
  methods: {
    async makePaymentClicked() {
      this.$router.push(await appendFirmsIfAdminViewingClient("/billing", this.$route.params.clientKey))
    },
  },
})
